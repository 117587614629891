<script setup>
import { ref, computed } from 'vue'
import { useUserStore } from 'Stores/user'
import UpdateButton from 'Components/commons/buttons/UpdateButton.vue'

const userStore = useUserStore()

const currentFirstName = userStore.user.first_name || ''
const currentLastName = userStore.user.last_name || ''
const firstName = ref(currentFirstName)
const lastName = ref(currentLastName)

const firstNameError = ref('')
const lastNameError = ref('')

const isFormValid = computed(() => {
  return !(
    firstName.value.trim() === '' ||
    lastName.value.trim() === '' ||
    firstNameError.value !== '' ||
    lastNameError.value !== ''
  );
});

const isChanged = computed(() => {
  return (
    currentFirstName !== firstName.value.trim() ||
    currentLastName !== lastName.value.trim()
  );
});

const handleFirstNameInput = (event) => {
  firstName.value = event.target.value;
  firstNameError.value = firstName.value.trim() === '' ? 'Le prénom est requis' : ''
};

const handleLastNameInput = (event) => {
  lastName.value = event.target.value;
  lastNameError.value = lastName.value.trim() === '' ? 'Le nom est requis' : ''
};

const handleUpdateUserInfos = () => {
  userStore.updateUserInfos({
    first_name: firstName.value,
    last_name: lastName.value,
  })
}
</script>

<template>
  <div class="flex flex-col h-full w-full">
    <div class="text-xl font-roc mb-8 text-gray-4 dark:text-white">Identité</div>
    <div class="flex mb-4 w-full">
      <label class="relative mr-4 grow">
        <div class="absolute -top-5 text-md text-gray-4 dark:text-white">Prénom</div>
        <input @input="handleFirstNameInput" :value="firstName" type="text" :class="[
          'w-full rounded-md text-md bg-transparent focus:ring-0',
          firstNameError ? 'border-red text-red focus:border-red' : 'border dark:border-white dark:text-white'
        ]">
        <p v-if="firstNameError" class="text-red text-sm mt-2">{{ firstNameError }}</p>
      </label>
      <label class="relative grow">
        <div class="absolute -top-5 text-md text-gray-4 dark:text-white">Nom</div>
        <input @input="handleLastNameInput" :value="lastName" type="text" :class="[
          'w-full rounded-md text-md bg-transparent focus:ring-0',
          lastNameError ? 'border-red text-red focus:border-red' : 'border dark:border-white dark:text-white'
        ]">
        <p v-if="lastNameError" class="text-red text-sm mt-2">{{ lastNameError }}</p>
      </label>
    </div>
    <UpdateButton :disabled="!isFormValid || !isChanged" @click="handleUpdateUserInfos"></UpdateButton>
  </div>
</template>