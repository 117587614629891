<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.99888 13.5015C6.51336 13.5015 4.49846 11.4865 4.49846 9.00099C4.49846 6.51544 6.51336 4.5005 8.99888 4.5005C11.4844 4.5005 13.4993 6.51544 13.4993 9.00099C13.4993 11.4865 11.4844 13.5015 8.99888 13.5015Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6246 8.99874C14.6246 8.37687 15.1278 7.87369 15.7496 7.87369H16.875C17.4968 7.87369 18 8.37687 18 8.99874C18 9.62061 17.4968 10.1238 16.875 10.1238H15.7499C15.1278 10.1238 14.6246 9.62061 14.6246 8.99874Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5675 12.9764L15.364 13.7732C15.8037 14.2125 15.8037 14.9247 15.364 15.364C14.9247 15.8033 14.2126 15.8033 13.773 15.364L12.9762 14.5675C12.5368 14.1281 12.5368 13.4157 12.9762 12.9764C13.416 12.5367 14.1279 12.5367 14.5675 12.9764Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99888 14.6246C9.62074 14.6246 10.1239 15.1277 10.1239 15.7496V16.8749C10.1239 17.4968 9.62074 18 8.99888 18C8.37701 18 7.87384 17.4968 7.87384 16.8749L7.87497 15.7496C7.87384 15.1277 8.37814 14.6246 8.99888 14.6246Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.43136 12.9775C3.86984 12.537 4.58199 12.537 5.02132 12.9764C5.46064 13.4157 5.46064 14.1281 5.02132 14.5675L4.22564 15.3629C3.78631 15.8045 3.07416 15.8022 2.63484 15.3629C2.19551 14.9235 2.19438 14.2125 2.63484 13.7721L3.43136 12.9775Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.12279 7.87369L2.25035 7.87594C2.86997 7.87369 3.37314 8.37687 3.37314 8.99874C3.37539 9.62061 2.86997 10.1238 2.25035 10.1238H1.12504C0.500925 10.126 1.90735e-06 9.62061 1.90735e-06 8.99874C-0.00112343 8.37799 0.500925 7.87369 1.12279 7.87369Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.43136 5.02224L2.63596 4.22655C2.19326 3.78609 2.19663 3.07506 2.63596 2.63544C3.07444 2.1947 3.78631 2.1947 4.22564 2.63432L5.02132 3.43198C5.46064 3.86934 5.46064 4.58122 5.02132 5.02083C4.58424 5.46298 3.86984 5.46073 3.43136 5.02224Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99888 3.37291C8.37814 3.37741 7.87384 2.86973 7.87384 2.25011V1.12506C7.87384 0.500937 8.37701 7.51714e-06 8.99888 7.51714e-06C9.62074 -0.00224259 10.1239 0.500937 10.1239 1.12309L10.1217 2.25039C10.1239 2.86973 9.62074 3.37291 8.99888 3.37291Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5653 5.02112C14.1279 5.46045 13.418 5.46045 12.9773 5.01999C12.5346 4.58375 12.5368 3.86962 12.975 3.43114L13.7707 2.63572C14.2123 2.19386 14.9222 2.19723 15.3618 2.63572C15.8048 3.07421 15.8048 3.78609 15.3652 4.2257L14.5653 5.02112Z" fill="white"/>
  </svg>
</template>