const colors = {
    black: "#000000",
    blue: "#4E74F9",
    cream1: '#FFFCF0',
    cream2: '#FBF6E2',
    cream3: '#F7F0D4',
    cream4: '#F9EFC7',
    darkBlue: "#0B1D5F",
    gray1: '#545869',
    gray2: '#22242B',
    gray3: '#13151C',
    gray4: '#010410',
    green: "#64F0AD",
    red: "#F5113A",
    white: "#FFFFFF",
    yellow: "#F0AB27",
}

function ColorConverter(color) {
    return colors[color]
}

export default ColorConverter
