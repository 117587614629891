<script setup>
import { useDashboardStore } from 'Stores/dashboard'
import { defineProps, defineEmits, ref, computed } from 'vue';
import { passwordValidation } from 'Utils/passwordValidation';
import { EyeSvg, EyeOffSvg } from 'Components/commons/svg/index.js';
import ColorConverter from 'Utils/colors'

const props = defineProps({
  label: String,
  inputId: String,
  inputName: String,
  autoComplete: String,
  newPasswordValue: String,
});

const dashboard = useDashboardStore()

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

const isValid = ref(true);
const inputValue = ref('');
const showPassword = ref(false);

const emit = defineEmits(['update:inputState']);
const errorMessage = computed(() => {
  if (!isValid.value) {
    return 'Le mot de passe doit contenir au minimum 8 caractères, une majuscule, un chiffre et un caractère spécial';
  } else if (inputValue.value !== '' && props.newPasswordValue && inputValue.value !== props.newPasswordValue) {
    return 'Les mots de passe ne correspondent pas';
  } else {
    return '';
  }
});

const handleInput = (event) => {
  inputValue.value = event.target.value;
  isValid.value = passwordValidation(inputValue.value);

  emit('update:inputState', {
    inputName: props.inputName,
    inputValue: event.target.value,
    hasError: !isValid.value || (props.newPasswordValue && inputValue.value !== props.newPasswordValue),
  });
};

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};
</script>

<template>
  <div class="text-md text-gray-4 dark:text-white">
    <label for="inputId" class="w-full">
      {{ label }}
    </label>

    <div class="relative">
      <input
        :id="inputId"
        :name="inputName" 
        @input="handleInput" 
        :value="inputValue" 
        :autocomplete="autoComplete"
        :type="showPassword ? 'text' : 'password'"
        :class="[
          'w-full mt-2 rounded-md text-md bg-transparent focus:ring-0',
          (!isValid || (inputValue !== '' && props.newPasswordValue && inputValue !== props.newPasswordValue))
            ? 'border-red text-red focus:border-red' 
            : 'border dark:border-white dark:text-white'
        ]">

      <span 
        class="absolute top-1/2 mt-1 right-3 transform -translate-y-1/2 cursor-pointer"
        @click="togglePasswordVisibility"
      >
        <EyeSvg :color="SvgColor()" v-if="!showPassword" />
        <EyeOffSvg :color="SvgColor()" v-else />
      </span>
    </div>

    <p v-if="!isValid || (inputValue !== '' && props.newPasswordValue && inputValue !== props.newPasswordValue)" class="text-red text-sm mt-2">{{ errorMessage }}</p>
  </div>
</template>