<script setup>
import { useConversationStore } from 'Stores/conversation'
import { useDashboardStore } from 'Stores/dashboard'
const conversation = useConversationStore()
const dashboard = useDashboardStore()

async function handleSubmit() {
  if(!conversation.userInput || conversation.userInput.length < 3) return
  
  conversation.userInput = conversation.userInput.replace(/(\r\n|\n|\r|\")/gm, '')

  if (conversation.currentTeam.preferences.use_control_module) {
    conversation.controlQuery(conversation.userInput)
    await new Promise(r => setTimeout(r, 1000));
    if (conversation.shouldAnswer) {
      conversation.messages.pop()
      sendMessage() 
    }
  } else {
    sendMessage()
  }
}

function sendMessage() {
  if (dashboard.currentConversation.is_welcome) {
    conversation.sendSupportMessage(conversation.userInput)
  } else {
    conversation.sendMessage(conversation.userInput, conversation.currentTeam.llm)
  }
}
</script>

<template>
  <div class="relative w-full">
    <input
      :class="[
        'rounded-lg w-full border dark:border-white border-gray-4 border-2 dark:bg-gray-4 bg-cream-1 py-4 dark:text-white text-gray-4 placeholder-gray-4 dark:placeholder-white dark:placeholder-opacity-25 placeholder-opacity-25',
        (conversation.messagesExhausted || conversation.isLoading) && 'cursor-not-allowed'
      ]"
      type="text"
      :placeholder="conversation.messagesExhausted ? 'La limite quotidienne de messages a été atteinte.' : 'Commencez une conversation on écrivant ici...'"
      :disabled="conversation.messagesExhausted || conversation.isLoading"
      :value="conversation.userInput"
      @input="conversation.userInput = $event.target.value"
      @keyup.enter="handleSubmit"
    >
    <div class="absolute -top-6 left-0 h-6 w-full bg-gradient-to-t from-gray-4 opacity-0 dark:opacity-100"></div>
    <div class="absolute -top-6 left-0 h-6 w-full bg-gradient-to-t from-cream-1 dark:opacity-0 opacity-100"></div>
  </div>
</template>