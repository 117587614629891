/**
 * Generates a URL for accessing different Large Language Models (LLMs) via AWS Lambda.
 * This function takes the name of a Large Language Model as input and returns
 * the corresponding URL for accessing the model. Currently supports 'gpt-4',
 * and 'openChat'. If the specified model is not one of these, a default URL is returned.
 *
 * @param {string} llm - The name of the Large Language Model. Supported values are 'gpt-4', and 'openChat'.
 * @returns {string} The URL for accessing the specified Large Language Model. If the model is not recognized, returns a default URL.
 */
export function generateLambdaUrl(lambda) {
  const url = import.meta.env.VITE_EXPRESS_SERVER_ENDPOINT
  switch (lambda) {
    case 'gpt-4':
      return url + '/openai/query'
    case 'gpt-3.5-turbo':
      return url + '/openai/query'
    case 'openchat-3.5':
      return url + '/openchat/query'
    case 'pinecone':
      return url + '/pinecone/query'
    case 'qdrant':
      return url + '/qdrant/query'
    case 'control':
      return url + '/qdrant/control'
  }
}