<script setup>
import {onMounted} from 'vue'
import BadgeList from '../commons/lists/BadgeList.vue'
import {useConversationStore} from 'Stores/conversation'
import {useTeamStore} from 'Stores/team'

const teamStore = useTeamStore();
const conversationStore = useConversationStore();

onMounted(async () => {
    await teamStore.getAvailableLlms();
});

</script>
<template>
    <div class="flex flex-col h-full w-full">
        <div class="text-xl font-roc mb-8 text-gray-4 dark:text-white">Choix du LLM (large language model)</div>
        <p class="text-sm w-3/4 mb-6">
            Un modèle de langage à grande échelle (LLM) est un système informatique avancé
            qui, après avoir analysé une vaste collection de textes, est capable de comprendre et de générer du langage
            naturel. Il est utilisé pour des tâches telles que la rédaction de contenu, la réponse à des questions et la
            traduction, offrant des performances proches de celles d'un humain en matière de compréhension et
            d'utilisation du language.
        </p>
        <div 
            v-if="teamStore.availableLlms" 
            class="flex"
        >
            <BadgeList 
                v-for="(llm, key) in teamStore.availableLlms.llms"
                :key="key"
                :selected="conversationStore.currentTeam.preferences.llm === key"
                :data="llm"
            />
        </div>
    </div>
</template>
