<script setup>
import { OgmaAvatarSvg } from 'Components/commons/svg'
import Loader from 'Components/commons/Loader.vue'
import ColorConverter from 'Utils/colors'
import { useDashboardStore } from 'Stores/dashboard'
import { useConversationStore } from 'Stores/conversation'

const dashboard = useDashboardStore()
const conversation = useConversationStore()

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}
</script>

<template>
  <div class="flex items-start self-start pr-32">
      <OgmaAvatarSvg class="mr-6 w-9 min-w-9 h-9 min-h-9" :color="SvgColor()"></OgmaAvatarSvg>

      <div class="w-fit mb-6 flex flex-col items-end">
        <div class="flex items-center p-6 dark:text-white text-gray-4 rounded-lg mb-2 text-md text-left dark:bg-gray-3 bg-cream-3">
          <Loader></Loader>
          <div 
            v-if="conversation.isRetrying"
            class="ml-4 text-gray-4 dark:text-white"
          >
            Oops ! Ça prend un peu plus de temps que prévu...
          </div>
        </div>
      </div>
  </div>
</template>
