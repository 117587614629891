<script setup>
import DashboardContent from 'Components/layout/DashboardContent.vue'

import { useDashboardStore } from 'Stores/dashboard'
import { useConversationStore } from 'Stores/conversation'
const dashboard = useDashboardStore()
const conversation = useConversationStore()
conversation.getCurrentTeam()
conversation.getTotalMessagesToday()
dashboard.initializeCurrentPage()
dashboard.getConversations()

</script>

<template>
  <div :class="dashboard.darkModeEnabled && 'dark'" id="lightsetting">
    <DashboardContent></DashboardContent>
  </div>
</template>
