<script setup>
import ResetButton from 'Components/commons/buttons/ResetButton.vue'
import DeleteButton from 'Components/commons/buttons/DeleteButton.vue'
import { useDashboardStore } from 'Stores/dashboard'
import { useConversationStore } from 'Stores/conversation'
const dashboard = useDashboardStore()
const conversation = useConversationStore()


function FormattedUpdatedAt() {
  if(dashboard.currentConversation) {
    const date = new Date(dashboard.currentConversation.updated_at)
    return date.toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
  } else {
    return '00/00/0000'
  }
}

function MessagesCount() {
  return conversation.displayedMessages ? conversation.displayedMessages.length : 0
}

function ConversationName() {
  return dashboard.currentConversation ? dashboard.currentConversation.name : 'Conversation sans titre'
}

function ShowDeleteButton() {
  return dashboard.currentConversation ? !dashboard.currentConversation.is_welcome : false
}
</script>

<template>
  <div class="flex items-center h-full">
    <div class="flex w-full px-6 justify-between">
      <div v-if="dashboard.currentConversation" class="flex flex-col items-start">
        <div class="dark:text-white text-gray-4 mb-1 font-roc text-header">{{ConversationName()}}</div>
        <div class="text-gray-1 text-md">Le {{FormattedUpdatedAt()}} | {{MessagesCount()}} messages</div>
      </div>
      <div class="flex">
        <ResetButton></ResetButton>
        <DeleteButton v-if="ShowDeleteButton()" class="ml-3"></DeleteButton>
      </div>
    </div>
  </div>
</template>
