<script setup>
import {DocumentsSvg} from "Components/commons/svg";
import {useDashboardStore} from "Stores/dashboard.js";
import {useDocumentsStore} from "Stores/documents.js";
import ColorConverter from 'Utils/colors'
import DotsSvg from "../commons/svg/DotsSvg.vue";

const documentsStore = useDocumentsStore();
const dashboard = useDashboardStore()

const props = defineProps({
    document: {
        type: Object,
        required: true,
    },
});

function SvgColor() {
    return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

function IsSelected() {
    return documentsStore.currentDocument.id === props.document.id
}

function SelectDocument() {
    documentsStore.currentDocument = props.document
}

</script>

<template>
    <div v-if="documentsStore.viewMode === 'grid'" class="w-1/3 p-3 z-10" @click="SelectDocument()">
        <figure v-if="documentsStore.isLoading" class="flex flex-col w-full h-40 rounded-md overflow-hidden dark:bg-gray-2 bg-cream-4 opacity-50 pulse">
            <div class="w-3/4 mt-3 mx-auto rounded-t-md h-32 dark:bg-gray-2 bg-cream-2 opacity-25"></div>
            <figcaption
                class="flex items-center h-10 p-3 text-sm dark:text-white text-gray-4 dark:bg-gray-3 bg-cream-4">
                <div class="mr-2 w-6 h-6 dark:bg-gray-2 bg-cream-3 rounded-full pulse"></div>
                <div class="w-3/4 h-4 dark:bg-gray-2 bg-cream-3 rounded truncate pulse"></div>
                <div class="ml-auto w-2 h-6 dark:bg-gray-2 bg-cream-3 rounded-full pulse"></div>
            </figcaption>
        </figure>

        <figure 
            v-else
            class="relative flex flex-col justify-end h-40 rounded-md overflow-hidden dark:bg-gray-2 bg-cream-4 border border-2"
            :class="[IsSelected() ? 'border-blue' : 'border-transparent']">
            <DocumentsSvg :color="SvgColor()" :width="120" :height="120" class="z-0 absolute inset-0 m-auto"></DocumentsSvg>
            <figcaption
                class="z-10 flex items-center h-10 p-3 text-sm dark:text-white text-gray-4 dark:bg-gray-4 bg-cream-3">
                <span class="w-3/4 truncate">{{ document.file_name }}</span>
                <DotsSvg :color="SvgColor()" class="ml-auto"></DotsSvg>
            </figcaption>
        </figure>
    </div>

    <div v-if="documentsStore.viewMode === 'list'" class="w-full" @click="SelectDocument()">
        <div v-if="documentsStore.isLoading" class="flex items-center py-3 px-3 mb-3 rounded-md dark:bg-gray-2 bg-cream-2 opacity-50 pulse"></div>
        <div v-else :class="['z-10 flex w-full h-8 items-center py-3 px-3 -mt-px hover:bg-cream-4 dark:hover:bg-gray-2 border border-gray-1 cursor-pointer border border-2', IsSelected() ? 'border-blue' : 'border-transparent']">
            <div class="z-10 mr-6 w-2/4 truncate text-gray-4 dark:text-white">{{ document.file_name }}</div>
            <div class="z-10 mr-6 w-1/4 truncate text-gray-4 dark:text-white">{{ new Date(document.created_at).toLocaleDateString('fr')}}</div>
            <div class="z-10 mr-6 w-1/4 truncate text-gray-4 dark:text-white">{{ new Date(document.updated_at).toLocaleDateString('fr')}}</div>
        </div>
    </div>
</template>

<style scoped>
@keyframes pulse {
    0% {
        background-color: rgba(255, 255, 255, 0.1);
    }

    50% {
        background-color: rgba(255, 255, 255, 0.2);
    }

    100% {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.pulse {
    animation: pulse 3s infinite;
}
</style>
