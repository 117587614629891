<script setup>
import { 
  DocumentsSvg,
  MegaphoneSvg,
  MessageSvg, 
  StatisticsSvg, 
} from 'Components/commons/svg'
import { useDashboardStore } from 'Stores/dashboard'
import Link from 'Components/sidebar/Link.vue'
import { NavbarRoutes } from 'Utils/routes'

const icons = {
  DocumentsSvg,
  MegaphoneSvg,
  MessageSvg,
  StatisticsSvg,
}

const dashboard = useDashboardStore()
</script>

<template>
  <div class="flex flex-col w-full px-4">
    <Link
      v-for="route in NavbarRoutes"
      :key="route.name"
      :isActive="route.name === dashboard.currentPageName"
      :title="route.text"
      :path="route.path"
      @click="dashboard.setCurrentPage(route)"
    >
      <template #icon>
        <component :is="icons[route.icon]" class="m-4"></component>
      </template>
    </Link>
  </div>
</template>