import './bootstrap';

import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueClickAway from "vue3-click-away";

import App from './App.vue'

const app = createApp(App)

app.use(createPinia())
app.use(VueClickAway)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "ogma.lespetitsbots.com"],
    }),
  ],
  tracesSampleRate: 0.1,
});

app.mount('#app')
