<script setup>
import { useConversationStore } from 'Stores/conversation'
const conversation = useConversationStore()

function getProgressBarWidth() {
  return (conversation.totalMessagesToday / conversation.currentTeam.preferences.messages_per_day ) * 100
}

</script>
<template>
  <div v-if="conversation.currentTeam" class="w-full flex flex-col items-start px-4">
    <div class="relative w-full h-2 rounded-full dark:bg-gray-2 bg-cream-3">
      <div 
        class="absolute h-full rounded-full left-0 bg-blue"
        :style="{
          'width': getProgressBarWidth() + '%'
        }"
      ></div>
    </div>
    <div class="w-full text-gray-1 text-sm mt-2">
      Plus que {{ conversation.currentTeam.preferences.messages_per_day - conversation.totalMessagesToday }} messages sur {{ conversation.currentTeam.preferences.messages_per_day }} aujourd'hui
    </div>
  </div>
</template>