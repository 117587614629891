<script setup>
import { ref } from 'vue'
import { useDocumentsStore } from 'Stores/documents';
import UploadDocumentModal from "../commons/modals/UploadDocumentModal.vue";
import UpdateDocumentModal from "../commons/modals/UpdateDocumentModal.vue";
const documentsStore = useDocumentsStore();

function HandleClick() {
  ClickAnimation()
    documentsStore.setModalOpen('upload', true);
}

const addDocument = ref(null)

function ClickAnimation() {
  addDocument.value.classList.add('h-10', 'pb-1')
  addDocument.value.classList.remove('hover:h-11', 'hover:pb-2', 'h-12.5', 'pb-3')
  setTimeout(() => {
    addDocument.value.classList.remove('h-10', 'pb-1')
    addDocument.value.classList.add('hover:h-11', 'hover:pb-2', 'h-12.5', 'pb-3')
  }, 200)
}

</script>
<template>
  <div ref="addDocument" class="cursor-pointer h-12.5 hover:h-11 bg-gray-4 dark:bg-white rounded-lg pb-3 hover:pb-2" @click="HandleClick()">
    <div class="relative w-full px-10 h-full rounded-lg border border-2 text-md text-gray-4 dark:text-white border-gray-4 dark:border-white flex bg-white dark:bg-gray-4 items-center justify-between">
        Ajouter un document
    </div>
  </div>
    <UploadDocumentModal/>
    <UpdateDocumentModal/>
</template>
