import { laravelApi } from 'Utils/http';

/**
 * Create a message.
 * @param {string} payload.role
 * @param {string} payload.content
 * @param {string} payload.conversation_id
 * @return {Promise}
 */
export default async ({role, content, conversation_id}) =>
  await laravelApi.post(`/api/messages`, {role, content, conversation_id} );
