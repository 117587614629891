import { defineStore } from 'pinia'
import * as userApi from 'Api/users'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: window.User,
    qrCode: null,
    recoveryCodes: [],
    disabling: false,
    enabling: false,
    isLoading: false,
    hasError: false,
    errors: [],
  }),

  actions: {
    async logout() {
      await userApi.logout()
      location.reload(true)
    },

    async getQrCode() {
      const action = async () => {
        const { data } = await userApi.getTwoFactorQrCode()
        this.qrCode = data.svg

        this.isLoading = false
      }

      const errorFallback = function () {
        this.isLoading = false
        this.hasError = true
      }

      this.isLoading = true

      try {
        action()
      } catch (error) {
        errorFallback()
      }

      // return axios.get('/user/two-factor-qr-code').then(response => {
      //   this.qrCode = response.data.svg;
      // });
    },

    async getRecoveryCodes() {
      const action = async () => {
        const { data } = await userApi.getTwoFactorRecoveryCodes()
        this.recoveryCodes = data

        this.isLoading = false
      }

      const errorFallback = function () {
        this.isLoading = false
        this.hasError = true
      }

      this.isLoading = true

      try {
        action()
      } catch (error) {
        errorFallback()
      }

      // return axios.get('/user/two-factor-recovery-codes').then(response => {
      //   this.recoveryCodes = response.data;
      // });
    },

    async regenerateRecoveryCodes() {
      const action = async () => {
        await userApi.regenerateRecoveryCodes()
        this.getRecoveryCodes()
      }

      const errorFallback = function () {
        this.isLoading = false
        this.hasError = true
      }

      this.isLoading = true

      try {
        action()
      } catch (error) {
        errorFallback()
      }

      // axios.post('/user/two-factor-recovery-codes').then(response => {
      //   this.showRecoveryCodes();
      // });
    },

    async enableTwoFactorAuthentication() {
      const action = async () => {
        this.enabling = true
        await userApi.enableTwoFactorAuthentication()
        await this.getQrCode()
        await this.getRecoveryCodes()

        this.enabling = false
      }

      const errorFallback = function () {
        this.enabling = false
        this.isLoading = false
        this.hasError = true
      }

      this.isLoading = true

      try {
        action()
      } catch (error) {
        errorFallback()
      }

      // this.$inertia.post(
      //   '/user/two-factor-authentication',
      //   {},
      //   {
      //     preserveScroll: true,
      //     onSuccess: () =>
      //       Promise.all([this.showQrCode(), this.showRecoveryCodes()]),
      //     onFinish: () => (this.enabling = false),
      //   }
      // );
    },

    async disableTwoFactorAuthentication() {
      const action = async () => {
        this.disabling = true
        await userApi.disableTwoFactorAuthentication()

        this.disabling = false
      }

      const errorFallback = function () {
        this.disabling = false
        this.isLoading = false
        this.hasError = true
      }

      this.isLoading = true

      try {
        action()
      } catch (error) {
        errorFallback()
      }

      // this.$inertia.delete('/user/two-factor-authentication', {
      //   preserveScroll: true,
      //   onSuccess: () => (this.disabling = false),
      // });
    },

    async updateUserInfos(userInfos) {
      const action = async () => {
        await userApi.updateUserInfos(userInfos)
        this.user.first_name = userInfos.first_name
        this.user.last_name = userInfos.last_name
      }

      const errorFallback = (request) => {
        this.isLoading = false
        this.hasError = true
        this.errors = request.response.data;
      }

      this.isLoading = true

      try {
        await action()
      } catch (error) {
        errorFallback(error)
      }
    },

    async updateUserPassword(old_password, password) {
      const action = async () => {
        await userApi.updateUserPassword({old_password, password})
      }

      const errorFallback = (request) => {
        this.isLoading = false
        this.hasError = true
        this.errors = request.response.data;
      }

      this.isLoading = true

      try {
        await action()
      } catch (error) {
        errorFallback(error)
      }
    }
  }
})
