/**
 * Validates a password based on the following criteria:
 * - Length must be at least 8 characters
 * - Must contain at least one uppercase letter
 * - Must contain at least one digit
 * - Must contain at least one special character (!@#$%^&*(),.?":{}|<>)
 *
 * @param {string} password - The password to be validated
 * @returns {boolean} - True if the password is valid, false otherwise
 */

/**
 * Array of regular expressions representing the validation rules for a password.
 * @type {RegExp[]}
 */
const validationRules = [
  /.{8,}/, // At least 8 characters
  /[A-Z]/, // At least one uppercase letter
  /[0-9]/, // At least one digit
  /[!@#$%^&*(),.?":{}|<>]/ // At least one special character
]

export const passwordValidation = (password) => {
  return validationRules.every((rule) => rule.test(password))
}
