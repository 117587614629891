import { laravelApi } from 'Utils/http';

/**
 * Gets all the user's documents.
 * @param {string} payload.sort
 * @param {string} payload.type
 * @param {string} payload.query
 * @return {Promise}
 */
export default async ({sort, type, query}) =>
  await laravelApi.post(`/api/documents`, {sort, type, query} );
