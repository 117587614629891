<script setup>
import { useDashboardStore } from 'Stores/dashboard'
import { useUserStore } from 'Stores/user'
import { ref, watch } from 'vue';

const dashboard = useDashboardStore()
const userStore = useUserStore()
const isProfilePage = ref(dashboard.currentPageName === 'profile')

let firstName = ref(userStore.user.first_name)
let lastName = ref(userStore.user.last_name)

watch(() => dashboard.currentPageName, (newPageName) => {
  isProfilePage.value = newPageName === 'profile'
})

watch([() => userStore.user.first_name, () => userStore.user.last_name], ([newFirstName, newLastName]) => {
  firstName.value = newFirstName
  lastName.value = newLastName
})
</script>

<template>
  <div
    @click="dashboard.setCurrentPage({
      path: '/dashboard/profile',
      name: 'profile',
    })" 
    :class="['relative flex items-center h-full cursor-pointer hover:bg-cream-4 dark:hover:bg-gray-3', isProfilePage && 'bg-cream-4 dark:bg-gray-3']"
  >
    <div class="flex w-full px-4">
      <div class="bg-blue text-white rounded-full w-11 h-11 mr-6 flex items-center justify-center">
        {{ firstName[0].toUpperCase() + lastName[0].toUpperCase() }}
      </div>

      <div class="flex flex-col items-start">
        <div class="dark:text-white text-gray-4 mb-1 font-roc text-header">{{ firstName + ' ' + lastName }}</div>
        <div 
          @click="dashboard.setCurrentPage({
            path: '/dashboard/profile',
            name: 'profile',
          })" 
          class="text-gray-1 flex items-center text-md dark:hover:text-white hover:text-gray-4 cursor-pointer"
        >
          Paramètres du compte
        </div>
      </div>

      <div 
        v-if="isProfilePage" 
        class="absolute w-20 h-20 bg-blue rounded-full blur-2xl right-0 top-0 translate-x-1/2"
      ></div>
    </div>
  </div>
</template>
