<script setup>
import { defineProps } from 'vue';
import GridSvg from "Components/commons/svg/GridSvg.vue";
import ListSvg from "Components/commons/svg/ListSvg.vue";
import ColorConverter from 'Utils/colors'
import { useDashboardStore } from "Stores/dashboard.js";
import { useDocumentsStore } from "Stores/documents.js";
const dashboard = useDashboardStore()
const documents = useDocumentsStore()

const props = defineProps({
  fromPannel: {
    type: Boolean,
    default: false,
  },
});

function SvgColor(component) {
  if (dashboard.darkModeEnabled) {
    return documents.viewMode == component ? 'white' : ColorConverter('gray1')
  } else {
    return documents.viewMode == component ?  ColorConverter('gray4') : ColorConverter('gray1')
  }
}
</script>

<template>
  <div class="flex items-center">
    <div @click="documents.setViewMode('list')" class="cursor-pointer mr-2">
      <ListSvg 
        :color="SvgColor('list')" 
        :class="!fromPannel && 'h-4 w-4'"
      ></ListSvg>
    </div>
    <div @click="documents.setViewMode('grid')" class="cursor-pointer">
      <GridSvg 
        :color="SvgColor('grid')" 
        :class="!fromPannel && 'h-4 w-4'"
      ></GridSvg>
    </div>
  </div>
</template>