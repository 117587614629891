<script setup>
import {defineProps, ref} from 'vue';
import {DoubleArrowsSvg} from 'Components/commons/svg';
import UpdateDocumentModal from 'Components/commons/modals/UpdateDocumentModal.vue';
import {useDashboardStore} from 'Stores/dashboard.js';
import {useDocumentsStore} from "Stores/documents.js";
import ColorConverter from 'Utils/colors'

const dashboard = useDashboardStore()
const documents = useDocumentsStore()

defineProps({
    documentId: {
        type: Number,
        required: true,
    }
})

function SvgColor() {
    return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

const showUpdateModal = ref(false)

function closeUpdateModal() {
  showUpdateModal.value = false
}

function openUpdateModal() {
    documents.setModalOpen('update', true);
}

</script>

<template>
    <div
        class="w-full h-12.5 rounded-lg bg-cream-3 dark:bg-gray-2 hover:bg-cream-4 dark:hover:bg-gray-1 flex items-center cursor-pointer mb-4"
        @click="openUpdateModal()"
    >
        <DoubleArrowsSvg :color="SvgColor()" class="mx-4"></DoubleArrowsSvg>
        <div class="text-md text-gray-4 dark:text-white">Mettre à jour</div>
    </div>

    <UpdateDocumentModal :documentId="props.documentId" v-if="showUpdateModal" @close="closeUpdateModal()"></UpdateDocumentModal>

</template>
