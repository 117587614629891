<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
const emit = defineEmits(['files-dropped'])
const fileInput = ref(null)

let active = ref(false)
let inActiveTimeout = null

function setActive() {
    active.value = true
    clearTimeout(inActiveTimeout)
}
function setInactive() {
    inActiveTimeout = setTimeout(() => {
        active.value = false
    }, 50)
}

function onDrop(e) {
    setInactive()
    emit('files-dropped', [...e.dataTransfer.files])
}

function preventDefaults(e) {
    e.preventDefault()
}

function triggerFileInput() {
    if (fileInput.value) {
        fileInput.value.click()
    }
}

function onFilesSelected(e) {
    if (e.target.files) {
        emit('files-dropped', [...e.target.files])
        // Reset the input after files are selected
        e.target.value = ''
    }
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

onMounted(() => {
    events.forEach((eventName) => {
        document.body.addEventListener(eventName, preventDefaults)
    })
})

onUnmounted(() => {
    events.forEach((eventName) => {
        document.body.removeEventListener(eventName, preventDefaults)
    })
})
</script>
<template>
    <div
        :data-active="active"
        @click="triggerFileInput"
        @dragenter.prevent="setActive"
        @dragover.prevent="setActive"
        @dragleave.prevent="setInactive"
        @drop.prevent="onDrop"
    >
        <input
            type="file"
            ref="fileInput"
            multiple
            class="hidden"
            @change="onFilesSelected"
        />
        <slot :dropZoneActive="active"></slot>
    </div>
</template>
