import { laravelApi } from 'Utils/http';

/**
 * Sends a POST request to update user password.
 *
 * @param {Object} datas - The user passwords.
 * @returns {Promise} - A Promise that resolves to the response of the HTTP request.
*/

export default async ({old_password, password}) =>
  await laravelApi.post(`/api/user/update-password`, {old_password, password});
