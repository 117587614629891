<script setup>
import { ArrowBottomLeftSvg } from 'Components/commons/svg'
import Link from 'Components/sidebar/Link.vue'
import Conversation from 'Components/sidebar/Conversation.vue'

import { useConversationStore } from 'Stores/conversation'
import { useDashboardStore } from 'Stores/dashboard'
const conversationStore = useConversationStore()
const dashboardStore = useDashboardStore()

</script>
<template>
  <div class="flex flex-col w-full px-4 grow overflow-y-scroll h-px" id="conversations">
    <div>
      <Link
        :isActive="false"
        :disabled="true"
        title="Mes conversations"
        link="/"
        class="mb-3"
      >
        <template #icon>
          <ArrowBottomLeftSvg class="m-5"></ArrowBottomLeftSvg>
        </template>
      </Link>

      <Conversation
        v-for="conversation in dashboardStore.conversations"
        :key="conversation.id"
        :conversation="conversation"
        :isActive="conversation.id === dashboardStore.currentConversation.id"
        :disabled="conversationStore.isLoading"
      ></Conversation>
    </div>
  </div>
</template>
<style>
  #conversations{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #conversations::-webkit-scrollbar{
    display: none;
  }
</style>