<script setup>
import { OgmaAvatarSvg, CopySvg, CheckSvg } from 'Components/commons/svg'
import Loader from 'Components/commons/Loader.vue'
import ColorConverter from 'Utils/colors'
import { useConversationStore } from 'Stores/conversation'
import { useDashboardStore } from 'Stores/dashboard'
import { ref } from 'vue'
const conversation = useConversationStore()
const dashboard = useDashboardStore()
const props = defineProps({
  message: {
    type: Object,
    required: true
  }
})

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

function ComputedMessage() {
  return props.message.content == 'Internal Server Error' ? 
    "Une erreur est survenue, veuillez recharger la page. \n Ce chatbot est en développement et n'est peut être pas opérationnel pour le moment." : 
    props.message.content
}

function DisplayLoader() {
  return IsFromAssistant() && 
    props.message.content === '' && 
    props.message.id === conversation.nextMessageId - 1;
}

function IsFromAssistant() {
  return ['assistant', 'document'].includes(props.message.role);
}

function DisplaySources() {
  return conversation.tempSources 
    && conversation.tempSources.length > 0
    && IsFromAssistant()
    && props.message.id === conversation.nextMessageId - 1;
}

function DisplayNoResult() {
  return DisplaySources()
    && conversation.tempSources[0].text === 'Aucune réponse n\'a été trouvée parmis les documents fournis.';
}

function ShowSourcesModal(source, index) {
  dashboard.displaySourcesModal = true;
  dashboard.displayedSource = {
    ...source,
    index: index
  };
}

let copying = ref(false)

function copyToClipboard() {
  const el = document.createElement('textarea');
  el.value = props.message.content;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  copying.value = true
  setTimeout(() => {
    copying.value = false
  }, 800)
}
</script>

<template>
  <div :class="[
    'flex items-start xl:max-w-5xl max-w-none',
    IsFromAssistant() ? 'self-start xl:pr-80' : 'self-end xl:pl-80'
  ]">
      <OgmaAvatarSvg v-if="IsFromAssistant()" class="mr-6 w-9 min-w-9 h-9 min-h-9" :color="SvgColor()"></OgmaAvatarSvg>

      <div class="w-fit mb-6 flex flex-col items-end">
        <div :class="[
          'p-6 dark:text-white text-gray-4 rounded-lg mb-2 text-md',
          IsFromAssistant() ? 'text-left dark:bg-gray-3 bg-cream-3' : 'text-right dark:bg-gray-2 bg-cream-3'
        ]">
          <Loader v-if="DisplayLoader()"></Loader>
          {{ ComputedMessage() }}
        </div>

        <div class="flex items-center justify-end max-w-full">
          <div v-if="DisplayNoResult()" class="text-sm flex items-center dark:text-white text-gray-4 w-full justify-end"> 
            Aucune réponse n'a été trouvée parmis les documents fournis.
          </div>

          <div v-else-if="DisplaySources()" id="sourcesarea" class="text-sm flex items-center dark:text-white text-gray-4 w-full overflow-x-scroll justify-end">
            Sources&nbsp;:
            <div
              v-for="(source, index) in conversation.tempSources"
              :key="index"  
              @click="ShowSourcesModal(source, index)"
              class="max-w-[12rem] ml-2 text-white cursor-pointer bg-darkBlue rounded-full p-1 pr-2 flex items-center hover:bg-blue"
            >
              <span class="rounded-full bg-blue mr-1 w-4 h-4 min-w-[1rem] flex items-center justify-center">{{ index+1 }}</span>
              <span class="truncate">{{ source.fileName }}</span>
            </div>
          </div>

          <div v-if="DisplaySources()" class="w-px h-5 bg-gray-1 ml-2"></div>

          <div 
            @click="copyToClipboard()" 
            class="relative dark:text-white text-gray-4 opacity-80 hover:opacity-100 flex items-center justify-end ml-2 text-sm cursor-pointer border border-px rounded-full border-gray-1 px-1 py-0.5"
          >
            <CopySvg 
              class="absolute left-1"
              :color="SvgColor()"
              :style="{
                'strokeDasharray': 50,
                'strokeDashoffset': copying ? -50 : 0,
              }"
            ></CopySvg>
            <CheckSvg 
              class="absolute left-1"
              :color="SvgColor()"
              :style="{
                'strokeDasharray': 50,
                'strokeDashoffset': copying ? 0 : -50,
              }"
            ></CheckSvg>
            <span class="pl-4">Copier</span>
          </div>
        </div>
      </div>

      <div v-if="!IsFromAssistant()" class="bg-blue text-white rounded-full w-9 min-w-9 h-9 min-h-9 ml-6 flex items-center justify-center">
        UT
      </div>
  </div>
</template>

<style>
  #sourcesarea{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #sourcesarea::-webkit-scrollbar{
    display: none;
  }
</style>