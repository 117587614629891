<script setup>
import { ref, nextTick, watch, computed } from 'vue'
import Message from 'Components/conversation/Message.vue'
import LoaderMessage from 'Components/conversation/LoaderMessage.vue'
import SourcesModal from 'Components/commons/modals/SourcesModal.vue'
import { useConversationStore } from 'Stores/conversation'
import { useDashboardStore } from 'Stores/dashboard'
const conversation = useConversationStore()
const dashboard = useDashboardStore()

const chatarea = ref(null)
const messages = computed(() => conversation.messages)
watch(messages, () => {
    nextTick(() => {
      if(dashboard.currentConversation.is_welcome && conversation.messages.length == 7) {
        chatarea.value.scrollTop = 0
      } else {
        chatarea.value.scrollTop = chatarea.value.scrollHeight
      }
    })
}, { deep: true })

function CloseSourcesModal() {
  dashboard.displaySourcesModal = false
  dashboard.displayedSources = {}
}
</script>

<template>
  <div class="h-[76vh] overflow-y-scroll grow" ref='chatarea' id="chatarea">
    <div class="flex flex-col pt-6" data-testid="chat">
      <Message
      v-for="message in conversation.displayedMessages" 
      :key="message.id"
      :message="message"
      data-test-id="welcome-message"
      ></Message>
      <LoaderMessage
        v-if="conversation.isLoading && conversation.tempStatus != 'STREAMING'"
      ></LoaderMessage>
    </div>
  </div>

  <SourcesModal v-if="dashboard.displaySourcesModal" @close="CloseSourcesModal()"></SourcesModal>

</template>

<style>
  #chatarea{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #chatarea::-webkit-scrollbar{
    display: none;
  }
</style>
