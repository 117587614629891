<script setup>
import {ref} from 'vue';
import {XSvg, CopySvg, CheckSvg, ExternalLinkSvg} from 'Components/commons/svg';
import UpdateDocumentButton from 'Components/documents/UpdateDocumentButton.vue';
import DeleteDocumentButton from 'Components/documents/DeleteDocumentButton.vue';
import {useDashboardStore} from 'Stores/dashboard.js';
import {useDocumentsStore} from "Stores/documents.js";
import ColorConverter from 'Utils/colors'
import {DocumentsSvg} from "Components/commons/svg";

const dashboard = useDashboardStore()
const documents = useDocumentsStore()

function SvgColor() {
    return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

function ClosePannel() {
    documents.currentDocument = {}
}

let Copying = ref(false)

function copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = documents.currentDocument.summary;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.Copying = true
    setTimeout(() => {
        this.Copying = false
    }, 800)
}
</script>

<template>
    <div class="flex flex-col items-start h-full w-full p-4 pb-6">
        <XSvg
            :color="SvgColor()"
            class="self-end mb-4 cursor-pointer"
            @click="ClosePannel()"
        ></XSvg>

        <div class="w-full text-gray-4 dark:text-white text-lg mb-4">
            {{ documents.currentDocument.file_name }}
        </div>

        <div class="w-full cursor-pointer h-40 mt-1 bg-gray-4 dark:bg-white rounded-lg pb-4 mb-4 hover:pb-2 hover:pt-2">
            <a
                :href="`/documents/${documents.currentDocument.id}`" 
                target="_blank" 
                class="relative w-full h-40 -top-2 px-10 rounded-lg border-2 text-md text-gray-4 dark:text-white border-gray-4 dark:border-white flex dark:bg-gray-2 bg-cream-4 items-center justify-between overflow-hidden"
            >
                <ExternalLinkSvg class="absolute right-0 top-0 w-4 mt-1 mr-2" :color="SvgColor()" />
                <DocumentsSvg :color="SvgColor()" :width="120" :height="120" class="z-0 absolute inset-0 m-auto"></DocumentsSvg>
            </a>
        </div>

        <div class="w-full text-gray-4 dark:text-white text-sm mb-2">Ajouté le
            {{ new Date(documents.currentDocument.created_at).toLocaleDateString('fr') }}
        </div>
        <div class="w-full text-gray-4 dark:text-white text-sm mb-2">Édité le
            {{ new Date(documents.currentDocument.updated_at).toLocaleDateString('fr') }}
        </div>
        <div class="w-full h-px bg-gray-4 dark:bg-white mb-4"></div>

        <div class="w-full bg-cream-3 dark:bg-gray-3 rounded-lg border border-px dark:border-gray-2 p-4">
            <div class="flex w-full justify-between mb-3">
                <div class="text-gray-4 dark:text-white text-md">Résumé</div>
                <div @click="copyToClipboard()"
                     class="relative opacity-80 hover:opacity-100 flex items-center justify-end mx-4 text-sm cursor-pointer">
                    <CopySvg
                        class="absolute left-0 h-4 w-4"
                        :color="SvgColor()"
                        :style="{'strokeDasharray': 50,'strokeDashoffset': Copying ? -50 : 0}"
                    ></CopySvg>
                    <CheckSvg
                        class="absolute left-0 h-4 w-4"
                        :color="SvgColor()"
                        :style="{ 'strokeDasharray': 50, 'strokeDashoffset': Copying ? 0 : -50}"
                    ></CheckSvg>
                </div>
            </div>
            <div class="w-full text-gray-4 dark:text-white text-md mb-2">{{ documents.currentDocument.summary }}</div>
        </div>

        <div class="grow flex flex-col justify-end w-full">
            <UpdateDocumentButton @close="ClosePannel"></UpdateDocumentButton>
            <DeleteDocumentButton @close="ClosePannel"></DeleteDocumentButton>
        </div>

    </div>
</template>
