<script setup>
import {XSvg, AddFileSvg, InfoSvg, UploadCloud2Svg, PreviewDocumentSvg, TrashSvg, LoadingSvg, CheckSvg} from 'Components/commons/svg'
import DropZone from 'Components/commons/inputs/DropZone.vue'
import {useDocumentsStore} from 'Stores/documents.js';
import {useDashboardStore} from 'Stores/dashboard.js';
import ColorConverter from 'Utils/colors'
import { ref } from 'vue';

const documents = useDocumentsStore()
const dashboard = useDashboardStore()

const filesUploaded = ref(false);
const filesUploadMessage = ref('Fichiers en cours de téléchargement')

function SvgColor() {
    return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

function closeModal() {
    documents.setModalOpen('upload', false);
}

async function handleUpdate() {
    if (documents.filesEmpty || filesUploaded.value) return;

    try {
        await documents.deleteDocument(documents.currentDocument.id);
        await documents.uploadDocuments(documents.files);
        filesUploaded.value = true;
        filesUploadMessage.value = 'Tous les fichiers ont été téléchargés';
        await documents.getDocuments();
        setTimeout(() => {
            closeModal();
            documents.cleanFiles();
            filesUploaded.value = false;
        }, 2000); // Attendre 2 secondes avant de fermer
    } catch (error) {
        // Gérez les erreurs ici
        filesUploaded.value = true;
        filesUploadMessage.value = 'Une erreur est survenue. Veuillez contactez un administrateur.';
    }
}

</script>
<template>
    <div v-if="(documents.modalName === 'update' && documents.modalOpen)">
        <div class="fixed top-0 left-0 z-40 bg-gray-3 opacity-70 w-full h-full"></div>
        <div
            v-click-away="closeModal"
            class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-[44vw] max-h-[80vh] overflow-y-auto dark:bg-gray-4 bg-cream-3 rounded-lg flex flex-col p-8"
        >
            <div class="flex items-center mb-6">
                <div class="text-xl text-gray-4 dark:text-white">Mettre à jour le document</div>
                <XSvg :color="SvgColor()" @click="closeModal" class="ml-auto cursor-pointer"></XSvg>
            </div>
            <div class="dark:bg-gray-2 w-full dark:text-white text-gray-4 bg-cream-1 text-md p-3 rounded-md mb-6 flex items-center">
                <InfoSvg :color="SvgColor()" class="mr-3"></InfoSvg>
                La mise à jour du document entraîne son remplacement par le document que vous ajouterez.
            </div>
            <ul class="w-full text-md text-gray-4 dark:text-white list-disc mb-6 pl-4">
                <li>Privilégiez les documents écrits en français et peu formatés.</li>
                <li>Privilégiez les documents contenant peu ou pas d’images, elles ne seront pas interprétées pour le moment.</li>
                <li>Privilégiez les documents contenant peu ou pas de graphiques ou tableaux, ils peuvent être mal compris et produire des réponses erronées.</li>
                <li>Évitez les contenus longs et à faible caractère informatif (sommaires, annexes, etc.)</li>
                <li>N’hésitez pas à élaguer vos documents si nécessaire, les performances d’Ogma n’en seront que meilleurs.</li>
            </ul>

            <p v-show="filesUploaded">{{ filesUploadMessage }}</p>

            <DropZone
                v-show="!filesUploaded"
                class="drop-area w-full flex flex-wrap items-center"
                @files-dropped="documents.addFiles"
                #default="{ dropZoneActive }"
            >
                <div
                    :class="[
                        'flex flex-wrap items-center flex-col cursor-pointer dark:hover:bg-gray-2 hover:bg-cream-2 justify-center mb-6 border-2 rounded-md p-4 border-dashed w-full text-gray-4 dark:text-white',
                        dropZoneActive ? 'border-blue dark:bg-gray-2 bg-cream-2' : ''
                    ]"
                >
                    <div class="w-full flex flex-col justify-between">
                        <div class="w-full text-center">
                            <div v-if="!filesUploaded">
                                <div v-if="dropZoneActive">
                                    <UploadCloud2Svg class="display-inline w-full my-2"/>
                                    <div class="text-center text-blue">Déposer un document ici</div>
                                </div>
                                <div v-else>
                                    <AddFileSvg :color="SvgColor()" class="display-inline w-full my-2"/>
                                    <div class="text-center">Ajouter un document pdf de moins de 500mo</div>
                                </div>
                            </div>
                            <div v-else>
                                <CheckSvg :color="ColorConverter('green')" width="36" height="36" class="display-inline w-full my-2"/>
                                <div class="text-center">Tous les fichiers ont été mis à jours</div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul v-show="!documents.filesEmpty && !filesUploaded" class="text-center w-full justify-center mb-4">
                    <li v-for="file of documents.files" :key="file.id" class="w-full mb-4">
                        <div class="dark:bg-gray-2 bg-cream-2 rounded-md w-full text-left flex p-2 items-center">
                            <PreviewDocumentSvg/>
                            <p class="text-sm ml-2">{{ file.file.name }}</p>
                            <button
                                @click.stop="documents.removeFile(file)"
                                v-show="file.status === null"
                                class="close-icon ml-auto"
                                aria-label="Remove"
                            >
                                <TrashSvg color="red" width="18" height="18"/>
                            </button>
                            <LoadingSvg class="ml-auto" v-show="file.status === 'loading'"/>
                            <CheckSvg
                                :color="ColorConverter('green')"
                                width="24"
                                height="24"
                                class="ml-auto"
                                v-show="file.status === 'OK'"
                            />
                            <span class="status-indicator failure-indicator" v-show="file.status === false">Error</span>
                        </div>
                    </li>
                </ul>
            </DropZone>

            <button
                :class="[
                    'w-full h-10 flex flex-shrink-0 justify-center items-center text-md rounded-lg text-white dark:text-white',
                    (documents.filesEmpty || filesUploaded) ? 'bg-gray-1 dark:bg-gray-2 cursor-not-allowed' : 'bg-blue cursor-pointer'
                ]"
                @click.prevent="handleUpdate(); $emit('close')"
            >
                Mettre à jour
            </button>

        </div>
    </div>
</template>
