<script setup>
import Conversations from 'Components/sidebar/Conversations.vue'
import Navigation from 'Components/sidebar/Navigation.vue'
import MessagesProgressBar from 'Components/sidebar/MessagesProgressBar.vue'
import LightSwitch from 'Components/commons/buttons/LightSwitch.vue'
import Logout from 'Components/commons/buttons/Logout.vue'
import CreateConversation from 'Components/sidebar/CreateConversation.vue'
import { useDashboardStore } from 'Stores/dashboard'

const dashboard = useDashboardStore()
</script>

<template>
  <div class="flex flex-col h-full w-full">
    <Navigation></Navigation>

    <div v-if="dashboard.currentPageName == 'dashboard'" class="relative h-px mx-4 mt-6 bg-gray-2 z-10">
      <div class="absolute top-px left-0 h-6 w-full bg-gradient-to-b from-gray-4 opacity-0 dark:opacity-100"></div>
      <div class="absolute top-px left-0 h-6 w-full bg-gradient-to-b from-cream-1 dark:opacity-0 opacity-100"></div>
    </div>
    <Conversations v-if="dashboard.currentPageName == 'dashboard'"></Conversations>
    <div v-if="dashboard.currentPageName == 'dashboard'" class="relative">
      <div class="absolute -top-6 left-0 h-6 w-full bg-gradient-to-t from-cream-1 dark:opacity-0 opacity-100"></div>
      <div class="absolute -top-6 left-0 h-6 w-full bg-gradient-to-t from-gray-4 opacity-0 dark:opacity-100"></div>
    </div>

    <CreateConversation v-if="dashboard.currentPageName == 'dashboard'" class="m-5"></CreateConversation>
    <MessagesProgressBar v-if="dashboard.currentPageName == 'dashboard'"></MessagesProgressBar>
    <div v-else class="grow"></div>
    <div class="relative h-32 flex items-end">
      <div class="flex flex-wrap mx-4 mb-6 gap-4">
        <div class="h-12.5 flex items-end"><LightSwitch class=""></LightSwitch></div>
        <div class="h-12.5 flex items-end"><Logout class="flex-grow"></Logout></div>
      </div>
    </div>
  </div>
</template>