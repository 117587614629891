<script setup>
import { LogOutSvg } from 'Components/commons/svg'
import { ref } from 'vue'
import { useUserStore } from 'Stores/user'
import { useDashboardStore } from 'Stores/dashboard'
import ColorConverter from 'Utils/colors'

const dashboard = useDashboardStore()

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

const user = useUserStore()

function HandleLogout() {
  ClickAnimation()
  user.logout()
}

const logout = ref(null)

function ClickAnimation() {
  logout.value.classList.add('h-10', 'pb-1')
  logout.value.classList.remove('hover:h-11', 'hover:pb-2', 'h-12.5', 'pb-3')
  setTimeout(() => {
    logout.value.classList.remove('h-10', 'pb-1')
    logout.value.classList.add('hover:h-11', 'hover:pb-2', 'h-12.5', 'pb-3')
  }, 200)
}

</script>
<template>
  <div ref="logout" class="cursor-pointer h-12.5 hover:h-11 bg-gray-4 dark:bg-white rounded-lg pb-3 hover:pb-2" @click="HandleLogout()">
    <div class="hidden xl:flex relative w-full px-10 h-full rounded-lg border border-2 text-md text-gray-4 dark:text-white border-gray-4 dark:border-white bg-white dark:bg-gray-4 items-center justify-between">
        Me déconnecter
    </div>
    <div class="flex xl:hidden relative w-full px-2 h-full rounded-lg border border-2 text-md text-gray-4 dark:text-white border-gray-4 dark:border-white bg-white dark:bg-gray-4 items-center justify-between">
        <LogOutSvg :color="SvgColor()"></LogOutSvg>
    </div>
  </div>
</template>