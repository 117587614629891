<script setup>
import {onMounted, computed, ref} from 'vue';
import {useConversationStore} from 'Stores/conversation';
import {useDocumentsStore} from "Stores/documents";
import InfoPopup from "../commons/InfoPopup.vue";

const conversationStore = useConversationStore();
const documentsStore = useDocumentsStore();

const isHovering = ref(false);

onMounted(() => {
    conversationStore.getCurrentTeam();
});

const popupMessage = computed(() => {
    let displayText = 'Vous pouvez mettre à jour jusqu’à \n' +
        '5 fois/jour l’ensemble des documents.'
    if (conversationStore.currentTeam) {
        switch (conversationStore.currentTeam.sync) {
            case 'false':
                displayText = 'Vous pouvez mettre à jour jusqu’à 5 fois/jour l’ensemble des documents.';
                break;
            case 'pending':
                displayText = 'Mise à jour en cours';
                break;
            case 'true':
                displayText = "Vous n’avez pas besoin de lancer la mise à jour car vous n’avez pas ajouté, supprimé ou mis à jour de documents."
                break;
            case 'error':
                displayText = 'Erreur de mise à jour. Veuillez contacter le service client.';
                break;
            case 'exceeded':
                displayText = 'Attendez demain pour pouvoir lancer une nouvelle mise à jour.'
                break;
        }
    }

    return displayText;
});

const enableButton = computed(() => {
    if (conversationStore.currentTeam) {
        return conversationStore.currentTeam.sync === 'false';
    }
})

function handleMouseEnter() {
    isHovering.value = true;
}

function handleMouseLeave() {
    isHovering.value = false;
}

function handleClick() {
    if (conversationStore.currentTeam.sync === 'false') {
        documentsStore.synchronizeData();
        setTimeout(()=>conversationStore.getCurrentTeam(), 1000);
    }
}

</script>
<template>
    <div class="relative">
        <div :class="['w-[202px] h-12.5 hover:h-11 bg-gray-4 dark:bg-white rounded-lg pb-3 hover:pb-2',
  enableButton ? 'cursor-pointer' : 'opacity-30 cursor-not-allowed'
]"
             @click="handleClick()"
             @mouseenter="handleMouseEnter"
             @mouseleave="handleMouseLeave">
            <div
                class="relative w-full pl-5  h-full rounded-lg border border-2 text-md text-gray-4 dark:text-white border-gray-4 dark:border-white flex bg-white dark:bg-gray-4 items-center justify-between">
                Lancer la mise à jour
            </div>
        </div>
        <InfoPopup :text="popupMessage" v-if="isHovering"/>
    </div>
</template>
