import { laravelApi } from 'Utils/http';

/**
 * Sends a POST request to update user information.
 *
 * @param {Object} userInfos - The user information to update.
 * @returns {Promise} - A Promise that resolves to the response of the HTTP request.
*/

export default async ({first_name, last_name}) => {
  return await laravelApi.post(`/api/user/update-infos`, {first_name, last_name});
};
