<script setup>
import { SunSvg, MoonSvg } from 'Components/commons/svg'
import { ref } from 'vue'
import { useDashboardStore } from 'Stores/dashboard'
const dashboard = useDashboardStore()

function ToggleDarkMode() {
  ClickAnimation()
  let nextTheme = dashboard.darkModeEnabled ? 'light' : 'dark';
  sessionStorage.setItem('theme', nextTheme);
  dashboard.darkModeEnabled = !dashboard.darkModeEnabled
}

const lightswitch = ref(null)

function ClickAnimation() {
  lightswitch.value.classList.add('h-10', 'pb-1')
  lightswitch.value.classList.remove('hover:h-11', 'hover:pb-2', 'h-12.5', 'pb-3')
  setTimeout(() => {
    lightswitch.value.classList.remove('h-10', 'pb-1')
    lightswitch.value.classList.add('hover:h-11', 'hover:pb-2', 'h-12.5', 'pb-3')
  }, 200)
}

</script>
<template>
  <div ref="lightswitch" class="cursor-pointer w-20 h-12.5 hover:h-11 bg-gray-4 dark:bg-white rounded-lg pb-3 hover:pb-2" @click="ToggleDarkMode()">
    <div class="relative w-full h-full rounded-lg border border-2 border-gray-4 dark:border-white flex bg-white dark:bg-gray-4 items-center justify-between">
      <div class="z-10 rounded-md h-full w-1/2 border-2 border-white dark:border-gray-4 bg-gray-4 dark:bg-white absolute dark:left-0 left-[2.4rem]"></div>
      <MoonSvg class="z-20 ml-2.5"></MoonSvg>
      <SunSvg class="z-20 mr-2.5"></SunSvg>
    </div>
  </div>
</template>