const NavbarRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    text: 'Conversations',
    icon: 'MessageSvg',
  },
  {
    path: '/dashboard/documents',
    name: 'documents',
    text: 'Documents',
    icon: 'DocumentsSvg',
  },
  // {
  //   path: '/dashboard/statistics',
  //   name: 'statistics',
  //   text: 'Statistiques',
  //   icon: 'StatisticsSvg',
  // },
];

const Routes = [
  ...NavbarRoutes,
  {
    path: '/dashboard/profile',
    name: 'profile',
  },
];

export {
  NavbarRoutes,
  Routes
};
