<script setup>
import { ref } from 'vue';
import { TrashSvg } from 'Components/commons/svg';
import DeleteDocumentModal from 'Components/commons/modals/DeleteDocumentModal.vue';
import { useDashboardStore } from 'Stores/dashboard.js';
import { useDocumentsStore } from "Stores/documents.js";
import ColorConverter from 'Utils/colors'

const dashboard = useDashboardStore()
const documents = useDocumentsStore()

const showDeletionModal = ref(false)

function SvgColor() {
  return dashboard.darkModeEnabled ? ColorConverter('red') : ColorConverter('gray4')
}

function closeDeletionModal() {
  showDeletionModal.value = false
}

</script>

<template>
  <div 
    class="w-full h-12.5 rounded-lg bg-cream-3 dark:bg-gray-2 hover:bg-cream-4 dark:hover:bg-gray-1 flex items-center cursor-pointer"
    @click="showDeletionModal = true"
  >
    <TrashSvg :color="SvgColor()" class="mx-4 h-5 w-5"></TrashSvg>
    <div class="text-md text-gray-4 dark:text-red">Supprimer</div>
  </div>

  <DeleteDocumentModal v-if="showDeletionModal" @close="closeDeletionModal()"></DeleteDocumentModal>
  
</template>