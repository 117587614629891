<script setup>
import ColorConverter from 'Utils/colors'
import { NewConversationSvg } from 'Components/commons/svg'
import { useDashboardStore } from 'Stores/dashboard'
const dashboard = useDashboardStore()

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}
</script>
<template>
  <div 
    @click="dashboard.createConversation()"
    class="flex items-center text-gray-4 dark:text-white text-md h-12.5 rounded-lg overflow-hidden cursor-pointer py-4 hover:bg-cream-4 dark:hover:bg-gray-3 bg-cream-3 dark:bg-gray-2"
  >
    <div class="relative">
      <NewConversationSvg class="mx-4" :color="SvgColor()"></NewConversationSvg>
    </div>
    Nouvelle conversation
  </div>
</template>