/**
 * Updates a document.
 * @param {string} payload.documentId
 * @param {object} payload.formData
 *
 * @return {Promise}
 */
export default async ({documentId, formData}) =>
  await axios.put(`/api/documents/${documentId}`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
