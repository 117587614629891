<script setup>
import { ref } from 'vue';
import { ChevronDownSvg, SortIconSvg } from 'Components/commons/svg';
import { useDashboardStore } from "Stores/dashboard.js";
import { useDocumentsStore } from "Stores/documents.js";
import ColorConverter from 'Utils/colors'

const dashboard = useDashboardStore()
const documents = useDocumentsStore()

const ShowMenu = ref(false)

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

function SortText(sortingMethod) {
  switch (sortingMethod) {
    case 'recent':
      return 'Plus récents'
    case 'old':
      return 'Plus anciens'
    case 'a-z':
      return 'A -> Z'
    case 'z-a':
      return 'Z -> A'
  }
}

function onClickAway(){
  ShowMenu.value = false
}
</script>

<template>
  <div class="relative w-40">
    <div
      class="flex w-full h-full px-4 items-center justify-between border rounded-md text-md border-gray-4 bg-transparent dark:border-white focus:ring-0 cursor-pointer"
      @click="ShowMenu = !ShowMenu"
      v-click-away="onClickAway"
    >
      <SortIconSvg :color="SvgColor()"></SortIconSvg>
      <div class="dark:text-white text-gray-4">{{ SortText(documents.requiredSort) }}</div>
      <ChevronDownSvg 
        :class="['transform', ShowMenu ? 'rotate-0' : '-rotate-90']"
        :color="SvgColor()"
      ></ChevronDownSvg>
    </div>  

    <div :class="['absolute right-0 top-10 z-20 flex flex-col border-gray-4 dark:border-white border rounded-lg dark:bg-gray-4 bg-cream-1', !ShowMenu && 'hidden']">
      <div
        v-for="sortingMethod in ['recent', 'old', 'a-z', 'z-a']"  
        :key="sortingMethod"
        @click="documents.setRequiredSort(sortingMethod)"
        :class="[
          'rounded-lg text-md dark:hover:text-white hover:text-gray-4 dark:bg-gray-4 bg-cream-1 w-full text-end cursor-pointer px-2 py-1', 
          documents.requiredSort === sortingMethod ? 'text-gray-4 dark:text-white' : 'text-gray-1 dark:text-gray-1'
        ]"
      >
        {{ SortText(sortingMethod) }}
      </div>
    </div>
  </div>
</template>
