import { defineStore } from 'pinia';
import * as teamApi from 'Api/teams';

export const useTeamStore = defineStore('team', {
    state: () => ({
        availableLlms: null,
        hasError: false,
    }),

    actions: {
        async getAvailableLlms() {
            try {
                const { data } = await teamApi.getAvailableLlms();
                this.availableLlms = data;
            } catch (error) {
                this.hasError = true;
            }
        },
    },
});
