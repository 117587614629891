<script setup>
import { ref } from 'vue'
import { useDashboardStore } from 'Stores/dashboard';

const dashboardStore = useDashboardStore()
const submitIsHovered = ref(false)
const cancelIsHovered = ref(false)

</script>

<template>
  <div :id="'conversation_actions'">
    <button
      @click="() => $emit('submit')"
      @mouseenter="() => submitIsHovered = true"
      @mouseleave="() => submitIsHovered = false" 
      class="w-5 h-5 flex items-center justify-center hover:bg-cream-1 hover:dark:bg-gray-3 rounded-full"
    >
      <svg width="10" height="7" viewBox="0 0 10 7" :fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          :style="{ fill: submitIsHovered ? '#31EF94' : (dashboardStore.darkModeEnabled ? '#FFF' : '#010410') }" 
          fill-rule="evenodd" clip-rule="evenodd" d="M9.35355 0.646447C9.54882 0.841709 9.54882 1.15829 9.35355 1.35355L3.85355 6.85355C3.65829 7.04882 3.34171 7.04882 3.14645 6.85355L0.646447 4.35355C0.451184 4.15829 0.451184 3.84171 0.646447 3.64645C0.841709 3.45118 1.15829 3.45118 1.35355 3.64645L3.5 5.79289L8.64645 0.646447C8.84171 0.451184 9.15829 0.451184 9.35355 0.646447Z"/>
      </svg>
    </button>
    
    <button
      @click="() => $emit('cancel')"
      @mouseenter="() => cancelIsHovered = true" 
      @mouseleave="() => cancelIsHovered = false"
      class="w-5 h-5 flex items-center justify-center hover:bg-cream-1 hover:dark:bg-gray-3 rounded-full"
    >
      <svg width="8" height="8" viewBox="0 0 8 8" :fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          :style="{ fill: cancelIsHovered ? '#F5113A' : (dashboardStore.darkModeEnabled ? '#FFF' : '#010410') }" 
          fill-rule="evenodd" clip-rule="evenodd" d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.70711 4L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L4 4.70711L1.35355 7.35355C1.15829 7.54882 0.841709 7.54882 0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645L3.29289 4L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"/>
      </svg>
    </button>
  </div>
</template>
