<script setup>
import { computed, ref } from 'vue'
import ColorConverter from 'Utils/colors'
import { useDashboardStore } from 'Stores/dashboard';
import { useConversationStore } from 'Stores/conversation';
import PencilSvg from '../commons/svg/PencilSvg.vue';
import ConversationActions from './ConversationActions.vue';

const dashboardStore = useDashboardStore()
const conversationStore = useConversationStore()

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  conversation: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const isEditing = ref(false);

const iconColor = computed(() => {
  return props.isActive ? 
    (dashboardStore.darkModeEnabled ? 'white' : 'gray4') : 
    (dashboardStore.darkModeEnabled ? 'gray1' : 'gray1')
});

const focusConversationInput = () => {
  const input = document.getElementById(props.conversation.id + '_input');
  isEditing.value = true;
  input.focus();
  input.select();
}

const handleSubmit = () => {
  const input = document.getElementById(props.conversation.id + '_input');
  const newName = input.value;

  if (newName !== props.conversation.name) {
    conversationStore.updateConversationName(newName);
  }

  isEditing.value = false;
}

const handleCancel = () => {
  const input = document.getElementById(props.conversation.id + '_input');
  input.value = props.conversation.name;
  isEditing.value = false;
}
</script>

<template>
  <div 
    :class="[
      'flex items-center mt-3 text-gray-1 text-md h-12.5 rounded-lg overflow-hidden justify-between',
      isActive ? 'bg-cream-3 dark:bg-gray-2 text-gray-4 dark:text-white' : 'hover:bg-cream-2 dark:hover:bg-gray-3',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer'
    ]" 
    @click="!disabled && dashboardStore.setCurrentConversation(conversation)"
    :title="conversation.id + '_conversation'"
    >
    <div class="flex items-center truncate w-full">
      <div :class="[`bg-${conversation.color} h-2.5 w-2.5 min-w-2.5 rounded-full my-5 ml-5 mr-3`]" :style="(isActive && dashboardStore.darkModeEnabled) && {
        'box-shadow': '0 0 30px 5px ' + ColorConverter(conversation.color)
      }"></div>
      <div class="relative">
        <input 
          :id="conversation.id + '_input'" 
          type="text" 
          :disabled="!isEditing" 
          :class="[
            'truncate mr-3 text-md py-2 px-3 rounded-md border-none focus:outline-none focus:ring-0 w-full',
            isActive ? 'text-gray-4 dark:text-white' : 'text-gray-1 dark:text-gray-1',
            isEditing ? 'bg-cream-1 dark:bg-gray-1' : 'bg-transparent',
          ]" 
          :value="conversation.name"
        >
        <div 
          @click="()=>{
            !disabled && dashboardStore.setCurrentConversation(conversation);
            isEditing && focusConversationInput();
          }"
          class="absolute top-0 left-0 right-0 bottom-0"
        ></div>

      </div>
    </div>
    <button 
      v-if="!conversation.is_welcome && !isEditing"
      :onClick="focusConversationInput" 
      class="rounded-md w-6 min-w-[6] h-6 flex justify-around items-center mx-3"
    >
      <PencilSvg :color="isEditing ? 'blue' : iconColor"/>
    </button>
    <ConversationActions 
      v-if="isEditing" 
      @submit="handleSubmit"
      @cancel="handleCancel"
      class="flex gap-2 p-1 mr-2 rounded-md"
    ></ConversationActions>
  </div>
</template>
