import {defineStore} from 'pinia'
import * as documentsApi from 'Api/documents'

export const useDocumentsStore = defineStore('documents', {
    state: () => ({
        isLoading: false,
        hasError: false,
        documents: [],
        currentDocument: {},
        searchInput: '',
        requiredType: '',
        requiredSort: 'recent',
        viewMode: 'grid',
        currentModal: {},
        modalOpen: false,
        modalName: 'upload',

        files: [],
    }),

    getters: {
        filesEmpty: (state) => {
          return state.files.length === 0;
        },
    },

    actions: {
        async getDocuments() {
            const action = async () => {
                const {data} = await documentsApi.listDocuments({
                    sort: this.requiredSort,
                    type: this.requiredType,
                    query: this.searchInput
                })
                this.documents = data
                this.isLoading = false;
            };

            const errorFallback = function () {
                this.isLoading = false;
                this.hasError = true
            }

            this.isLoading = true

            try {
                action()
            } catch (error) {
                errorFallback()
            }
        },

        async synchronizeData() {
            const action = async () => {
                await documentsApi.synchronizeDocuments()
            };

            const errorFallback = function () {
                this.isLoading = false;
                this.hasError = true
            }

            this.isLoading = true

            try {
                action()
            } catch (error) {
                errorFallback()
            }
            await this.getDocuments();
        },

        async uploadDocument(document) {
            const action = async () => {
                let formData = new FormData()
                formData.append('file', document.file)
                formData.append('name', document.name)
                document.status = 'loading'

                let response = await documentsApi.uploadDocument(formData);

                document.status = response.statusText
                this.isLoading = false;
                return response
            };

            const errorFallback = function () {
                this.isLoading = false;
                this.hasError = true
            }

            this.isLoading = true

            try {
                action()
            } catch (error) {
                errorFallback()
            }
        },

        async uploadDocuments() {
            return Promise.all(this.files.map((file) => this.uploadDocument(file)))
        },

        async deleteDocument(documentId) {
            const action = async () => {
                const {data} = await documentsApi.deleteDocument(documentId);
                this.currentDocument = {};
                await this.getDocuments();
                this.isLoading = false;
            };

            const errorFallback = function () {
                this.isLoading = false;
                this.hasError = true
            }

            this.isLoading = true

            try {
                action()
            } catch (error) {
                errorFallback()
            }
        },

        async updateDocument(documentId, formData) {
            const action = async () => {
                const {data} = await documentsApi.updateDocument({documentId, formData});
                await this.getDocuments()
                this.isLoading = false;
            };

            const errorFallback = function () {
                this.isLoading = false;
                this.hasError = true
            }

            this.isLoading = true

            try {
                action()
            } catch (error) {
                errorFallback()
            }
        },

        setSearchInput(value) {
            this.searchInput = value
            this.getDocuments()
        },

        setModalOpen(name, value = true) {
            this.modalName = name;
            this.modalOpen = value;
        },

        setRequiredSort(value) {
            this.requiredSort = value
            this.getDocuments()
        },

        setViewMode(value) {
            this.viewMode = value
        },

        cleanFiles() {
            this.files = [];
        },

        /*
        * FILE LIST METHODS START ------------------------------------------------------------------ FILE LIST METHODS START
        */
        addFiles(newFiles) {
            const newUploadableFiles = newFiles
                .map(file => {
                    const newName = this.sanitizeFileName(file.name);
                    const newFile = new File([file], newName, {type: file.type, lastModified: file.lastModified});
                    return new UploadableFile(newFile);
                })
                .filter(file => this.hasCorrectFileType(file.file.type)) // Nouveau filtre pour vérifier le type de fichier
                .filter(file => this.hasValidFileSize(file.file.size))    // Nouveau filtre pour vérifier la taille du fichier
                .filter(file => !this.fileExists(file.id));

            this.files = this.files.concat(newUploadableFiles)
        },

        sanitizeFileName(filename) {
            return filename.replace(/[^\w\s-.]/g, '').replace(/\s+/g, '-');
        },

        fileExists(fileId) {
            return this.files.some(({id}) => id === fileId)
        },

        hasCorrectFileType(type) {
            return type === 'application/pdf';
        },

        hasValidFileSize(size) {
            return size < 524288000;  // 500mb in bytes
        },

        removeFile(file) {
            const index = this.files.indexOf(file)
            if (index > -1) this.files.splice(index, 1)
        },
        /*
        * FILE LIST METHODS END ---------------------------------------------------------------------- FILE LIST METHODS END
        */
    }
})

class UploadableFile {
    constructor(file) {
        this.file = file;
        this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
        this.url = URL.createObjectURL(file);
        this.status = null;
    }
}
