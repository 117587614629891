<script setup>
import UpdateButton from 'Components/commons/buttons/UpdateButton.vue';
import { useUserStore } from 'Stores/user';

const userStore = useUserStore();
const { user } = userStore;

function TwoFactorEnabled() {
  return !userStore.enabling && user.two_factor_enabled;
}

</script>

<template>
  <div class="flex flex-col h-full w-full">
    <div class="text-xl font-roc mb-8 text-gray-4 dark:text-white">Authentification à deux facteurs</div>
    <div class="text-md mb-4">
      Vous n'avez pas activé l'authentification à deux facteurs
    </div>
    <div class="text-sm mb-6">
      Lorsque l’authentification à deux factures est activée, vous serez invité à saisir un code aléatoire et sécurisé pendant l’authentification. Vous pouvez récupérer ce code à partir de l’application Google Authentificator de votre téléphone.
    </div>
    <div
      class="h-8 w-32 rounded-lg bg-blue text-md text-white flex items-center cursor-pointer justify-center"
      @click="ActivateTwoFactors"
    >
      Activer
    </div>
  </div>
</template>

      <!-- <div v-if="twoFactorEnabled">
        <div v-if="qrCode">
          <div class="mt-4 max-w-xl text-sm text-gray-600">
            <p class="font-semibold">
              {{ $t('security.twoFactorAuthentication.enabled.qrCodeDescription') }}
            </p>
          </div>

          <div
            class="mt-4 dark:p-4 dark:w-56 dark:bg-white"
            v-html="qrCode"
          ></div>
        </div>

        <div v-if="recoveryCodes.length > 0">
          <div class="mt-4 max-w-xl text-sm text-gray-600">
            <p class="font-semibold">
              {{ $t('security.twoFactorAuthentication.enabled.description2') }}
            </p>
          </div>

          <div
            class="grid gap-1 max-w-xl mt-4 px-4 py-4 font-mono text-sm bg-gray-100 rounded-lg"
          >
            <div v-for="code in recoveryCodes" :key="code">
              {{ code }}
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="mt-5">
        <div v-if="!twoFactorEnabled">
          <jet-confirms-password @confirmed="enableTwoFactorAuthentication">
            <jet-button
              type="button"
              :class="{ 'opacity-25': enabling }"
              :disabled="enabling"
            >
              {{ $t('global.enable') }}
            </jet-button>
          </jet-confirms-password>
        </div>

        <div v-else>
          <jet-confirms-password @confirmed="regenerateRecoveryCodes">
            <jet-secondary-button class="mr-3" v-if="recoveryCodes.length > 0">
              {{ $t('security.twoFactorAuthentication.enabled.regenerateCodes') }}
            </jet-secondary-button>
          </jet-confirms-password>

          <jet-confirms-password @confirmed="showRecoveryCodes">
            <jet-secondary-button
              class="mr-3"
              v-if="recoveryCodes.length === 0"
            >
              {{ $t('security.twoFactorAuthentication.enabled.showCodes') }}
            </jet-secondary-button>
          </jet-confirms-password>

          <jet-confirms-password @confirmed="disableTwoFactorAuthentication">
            <jet-danger-button
              :class="{ 'opacity-25': disabling }"
              :disabled="disabling"
            >
              {{ $t('global.disable') }}
            </jet-danger-button>
          </jet-confirms-password>
        </div>
      </div> -->
