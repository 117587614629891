import { defineStore } from 'pinia'
import { useDashboardStore } from 'Stores/dashboard'
import welcomeConversationConfig from 'Utils/welcomeConversationConfig.js'
import * as conversationApi from 'Api/conversations'
import * as messageApi from 'Api/messages'
import * as teamApi from 'Api/teams'
import { generateLambdaUrl } from '../utils/generateLambdaUrl';

let conversationTitleConfig = 'A partir du message suivant, trouve le nom de la conversation. Ta réponse doit impérativement contenir moins de 30 caractères et doit être formulée en français.';

export const useConversationStore = defineStore('conversation', {
  state: () => ({
    isLoading: false,
    isRetrying: false,
    hasError: false,
    nextMessageId: 1,
    messages: [],
    userInput: '',
    currentConversationId: null,
    searchAvailable: true,
    tempMessage: '',
    tempStatus: '',
    tempSources: [],
    currentTeam: null,
    totalMessagesToday: 0,
    shouldAnswer: true,
    queryControled: false,
  }),

  getters: {
    history: (state) => {
      return state.messages
        .filter((message, index) => message.role != 'system' || index > state.messages.length - 4)
        .map((message) => {
          return {
            role: message.role,
            content: message.content,
          }
        })
        .slice(-7)
    },
    displayedMessages: (state) => {
      return state.messages
        .filter((message) => message.content != '' && message.role != 'system')
        .map((message) => {
        return {
            id: message.id,
            role: message.role,
            content: message.content == '' ? '' : JSON.parse(message.content).content,
          }
        })
    },
    messagesExhausted: (state) => {
      return state.currentTeam ? state.totalMessagesToday >= state.currentTeam.preferences.messages_per_day : true
    },
  },

  actions: {
    async getCurrentTeam() {
      const action = async () => {
        const { data } = await teamApi.getCurrentTeam();
        this.currentTeam = data;
      };

      const errorFallback = function() {
        this.hasError = true
      }

      try { action() } catch (error) {
        errorFallback()
      }
    },

    addMessage(message) {
      this.messages.push(message)
    },

    reset() {
      this.isLoading = false
      this.hasError = false
      this.messages = [{
        id: 0,
        role: 'assistant',
        content: '{"type": "ANSWER", "content": "Bonjour, comment puis-je vous aider ?"}',
      }]
      this.userInput = ''
      this.tempSources = []
    },

    // Appends a token to the last message
    completeLastMessage(token) {
        this.messages[this.messages.length - 1].content = this.messages[this.messages.length - 1].content.slice(0, -2)
        this.messages[this.messages.length - 1].content += token + (token.includes('"}') ? '' : '"}')
    },

    handleNewToken(token) {
      this.isRetrying = false

      token = token.replace(/(\r\n|\\r\\n|\n|\\n|\r"|\\r")/gm, '')
      if (this.tempStatus == "STREAMING") {
        // If Ogma is currently writing his answer
        token = token.replace(/}|"/gm, '')
        this.completeLastMessage(token);
      } else if (this.tempStatus == "SEARCHING") {
        // If Ogma is building his query for Pinecone
        this.tempMessage += token
      } else if (this.tempMessage.includes('{"type": "ANSWER", "content": "')) {
        // If Ogma is about to start writing his answer
        this.messages[this.messages.length - 1].content += this.tempMessage + token + '"}'
        this.tempStatus = "STREAMING"
        this.tempMessage = ''
      } else if (this.tempMessage.includes('{"type": "SEARCH", "content": "')) {
        // If Ogma is about to start building his query for Pinecone
        this.tempStatus = "SEARCHING"
        this.tempMessage += token
      } else {
        // If we still don't know what type of message Ogma is sending
        this.tempMessage += token
      }
    },

    async handleStreamEnd() {
      let message = this.messages[this.messages.length - 1].content
      message = message.replace(/(\r\n|\\r\\n|\n|\\n|\r"|\\r")/gm, '')
      message = message.replace(/\\/gm, '\\\\')
      this.messages[this.messages.length - 1].content = message

      if (this.tempStatus == "STREAMING") {
        // If Ogma just finished writing his answer
        let tempContent = this.messages[this.messages.length - 1].content
        // We need to remove possible extra characters at the end of the message
        while (tempContent.slice(tempContent.length - 4) == '"}"}'){
          tempContent = tempContent.slice(0, -2)
          this.messages[this.messages.length - 1].content = tempContent
        }
        // If the message content is still not a valid JSON, we won't record it
        let jsonCheck = this.messages[this.messages.length - 1].content;
        try {
          JSON.parse(jsonCheck)
          messageApi.createMessage({
            role: 'assistant',
            content: this.messages[this.messages.length - 1].content,
            conversation_id: this.currentConversationId,
          });
        } catch (error) {
          messageApi.createMessage({
            role: 'assistant',
            content: '{"type": "ANSWER", "content": "Une erreur est survenue, ce message n\'a pas pu être enregistré correctement. Veuillez nous excuser pour la gêne occasionnée."}',
            conversation_id: this.currentConversationId,
          });
        }
        this.searchAvailable = true
        this.isLoading = false
        // If the conversation is new, we update its name
        const dashboardStore = useDashboardStore();
        dashboardStore.currentConversation.name == "Nouvelle conversation" && this.updateConversationName()
        await teamApi.incrementMessagesCount();
        this.getTotalMessagesToday();
      }

      else if (this.tempStatus == "SEARCHING") {
        // If Ogma just finished building his query for Pinecone
        this.completeLastMessage(this.tempMessage)
        this.messages[this.messages.length - 1].role = 'system'
        // If the message content is still not a valid JSON, we won't record it
        let jsonCheck = this.messages[this.messages.length - 1].content;
        try {
          JSON.parse(jsonCheck)
          messageApi.createMessage({
            role: 'system',
            content: this.messages[this.messages.length - 1].content,
            conversation_id: this.currentConversationId,
          });
        } catch (error) {
          messageApi.createMessage({
            role: 'system',
            content: '{"type": "ANSWER", "content": "Une erreur est survenue, ce message n\'a pas pu être enregistré correctement. Veuillez nous excuser pour la gêne occasionnée."}',
            conversation_id: this.currentConversationId,
          });
        }
        // We send the query to Pinecone
        this.searchQuery(JSON.parse(this.tempMessage).content)
        this.searchAvailable = false
      }

      this.tempMessage = ''
      this.tempStatus = ''
    },

    async updateConversationName(customUserInput) {
      const dashboardStore = useDashboardStore();
      
      const action = async () => {
        if (customUserInput) {
          conversationApi.updateConversation({
            name: customUserInput,
            conversationId: this.currentConversationId,
          });    
          dashboardStore.currentConversation.name = customUserInput;
        } else {
          let messages = [{
            content: conversationTitleConfig,
            role: 'system',
          }]
          messages.push({
            role: 'user',
            content: this.messages[this.messages.length - 2].content,
          })
          fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer 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',
            },
            body: JSON.stringify({
              model: "gpt-3.5-turbo",
              messages: messages,
              temperature: 0.7,
              max_tokens: 500,
              frequency_penalty: 0,
              presence_penalty: 0,
              stream: false,
            }),
          }).then((response) => {
            response.json().then(async (data) => {
              conversationApi.updateConversation({
                name: data.choices[0].message.content,
                conversationId: this.currentConversationId,
              });
              dashboardStore.currentConversation.name = data.choices[0].message.content;
            })
          })
        }
      };

      const errorFallback = function() {
        this.isLoading = false
        this.hasError = true
        this.addMessage({
          id: this.nextMessageId,
          role: 'system',
          content: '{"type": "ANSWER", "content": "Une erreur est survenue, veuillez recharger la page. \n Ogma est en cours de développement est n\'est peut être pas totalement opérationnel pour le moment."}',
        })
        this.nextMessageId++
      }

      try { action() } catch (error) {
        errorFallback()
      }
    },

    /*
     * SEND MESSAGE START ------------------------------------------------------------------ SEND MESSAGE START
     */
    async sendMessage(message, llm) {
      this.userInput = ''
      this.isLoading = true

      // We record and display the user's message
      this.addMessage({
        id: this.nextMessageId,
        role: 'user',
        content: '{"type": "QUERY", "content": "' + message + '"}',
      })
      messageApi.createMessage({
        role: 'user',
        content: '{"type": "QUERY", "content": "' + message + '"}',
        conversation_id: this.currentConversationId,
      });
      this.nextMessageId++
      const conversationHistory = this.history
      // We prepare an empty message for Ogma's answer
      this.addMessage({
        id: this.nextMessageId,
        role: 'assistant',
        content: '',
      })
      this.nextMessageId++

      let self = this;
      let attemptsCount = 1;

      const lambdaCall = function(attemptsCount, conversationHistory, self) {
        let abortController = new AbortController()
        let signal = abortController.signal

        const payload = {
          history: conversationHistory,
          searchAvailable: self.searchAvailable,
        }

        fetch(generateLambdaUrl(self.currentTeam.preferences.llm), {
          method: 'POST',
          signal: signal,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
        .then((response) => {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({ done, value }) => {
                  let answer = new TextDecoder().decode(value)
                  self.handleNewToken(answer)

                  if (done) {
                    self.handleStreamEnd()
                    controller.close()
                    return;
                  }

                  controller.enqueue(value);
                  return pump();
                });
              };
            },
          })
        })

        setTimeout(() => {
          if(self.tempStatus === "PENDING") {
            abortController.abort()
            attemptsCount++
            if(attemptsCount <= 3) {
              self.isRetrying = true
              lambdaCall(attemptsCount, conversationHistory, self)
            }
            else {
              self.isLoading = false
              self.isRetrying = false
              self.addMessage({
                id: self.nextMessageId,
                role: 'assistant',
                content: '{"type": "ANSWER", "content": "Une erreur est survenue, veuillez recharger la page. Si le problème persiste, vous pouvez essayer de poser votre question dans une nouvelle conversation."}',
              })
              self.nextMessageId++
            }
          }
        }, 10000);
      }

      self.tempStatus = "PENDING"

      lambdaCall(attemptsCount, conversationHistory, self)
    },
    /*
     * SEND MESSAGE END ---------------------------------------------------------------------- SEND MESSAGE END
     */

    /*
     * SEARCH QUERY START ------------------------------------------------------------------ SEARCH QUERY START
     */
    async searchQuery(query) {
      const action = async () => {
        const payload = {
          query: query,
          teamName: this.currentTeam.name,
          requiredResults: this.currentTeam.preferences.max_sources,
          similarityThreshold: this.currentTeam.preferences.similarity_threshold,
          indexName : 'ogma',
        }

        const response = await fetch(generateLambdaUrl(this.currentTeam.preferences.vector_db), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
        const data = await response.json()

        this.handleSources(data)
        this.sendSources(data, this.currentTeam.llm)
      }

      const errorFallback = function() {
        this.isLoading = false
        this.hasError = true
        this.addMessage({
          id: this.nextMessageId,
          role: 'system',
          content: '{"type": "ANSWER", "content": "Une erreur est survenue, veuillez recharger la page. \n Ogma est en cours de développement est n\'est peut être pas totalement opérationnel pour le moment."}',
        })
        this.nextMessageId++
      }

      try { action() } catch (error) {
        errorFallback()
      }
    },
    /*
     * SEARCH QUERY END ---------------------------------------------------------------------- SEARCH QUERY END
     */

    /*
     * SEND SOURCES START ------------------------------------------------------------------ SEND SOURCES START
     */
    async sendSources(sources, llm) {
      this.userInput = ''
      if(sources.length == 0) {
        sources.push({
          text: 'Aucune réponse n\'a été trouvée parmi les documents fournis.',
        })
      }
      // We record the sources found by Pinecone
      sources.forEach(source => {
        this.addMessage({
          id: this.nextMessageId,
          role: 'system',
          content: '{"type": "SEARCH_RESULT", "content": "' + source.text + '"}',
        })
        messageApi.createMessage({
          role: 'system',
          content: '{"type": "SEARCH_RESULT", "content": "' + source.text + '"}',
          conversation_id: this.currentConversationId,
        });
        this.nextMessageId++
      })

      let conversationHistory = this.history

      // We prepare an empty message for Ogma's answer
      this.addMessage({
        id: this.nextMessageId,
        role: 'assistant',
        content: '',
      })
      this.nextMessageId++

      let self = this;

      const payload = {
        history: conversationHistory,
        searchAvailable: self.searchAvailable,
      }

      fetch(generateLambdaUrl(self.currentTeam.preferences.llm), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                let answer = new TextDecoder().decode(value)
                self.handleNewToken(answer)

                if (done) {
                  self.handleStreamEnd()
                  controller.close()
                  return;
                }

                controller.enqueue(value);
                return pump();
              });
            };
          },
        })
      })
    },
    /*
     * SEND SOURCES END --------------------------------------------------------------------- SEND SOURCES END
     */

    /*
     * SEND SUPPORT MESSAGE START -------------------------------------------------- SEND SUPPORT MESSAGE START
     */
    async sendSupportMessage(message) {
      this.userInput = ''
      this.isLoading = true

      this.addMessage({
        id: this.nextMessageId,
        role: 'user',
        content: '{"type": "QUERY", "content": "' + message + '"}',
      })
      messageApi.createMessage({
        role: 'user',
        content: '{"type": "QUERY", "content": "' + message + '"}',
        conversation_id: this.currentConversationId,
      });
      this.nextMessageId++

      this.addMessage({
        id: this.nextMessageId,
        role: 'assistant',
        content: '',
      })
      this.nextMessageId++

      let supportMessages = [{
        content: welcomeConversationConfig,
        role: 'system',
      }]
      this.messages.forEach((message) => {
        supportMessages.push({
          content: message.content,
          role: message.role,
        })
      });
      supportMessages.push({
        role: 'user',
        content: message,
      })

      let self = this;

      fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 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',
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: supportMessages,
          temperature: 0.7,
          max_tokens: 500,
          frequency_penalty: 0,
          presence_penalty: 0,
          stream: true,
        }),
      })
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                let answer = new TextDecoder().decode(value)
                if (done || answer.slice(-8) == '[DONE]\n\n') {
                  self.handleStreamEnd()
                  controller.close()
                  return;
                }

                let splitedAnswer = answer.split('\n\n')
                splitedAnswer.pop()
                splitedAnswer.forEach((answer) => {
                  answer = JSON.parse(answer.slice(6)).choices[0].delta.content;
                  self.handleNewToken(answer);
                })

                controller.enqueue(value);
                return pump();
              });
            };
          },
        })
      })
    },
    /*
     * SEND SUPPORT MESSAGE END ------------------------------------------------------ SEND SUPPORT MESSAGE END
     */

    /*
     * QUERY CONTROL MODULE START ------------------------------------------------------------------ QUERY CONTROL MODULE START
     */
        async controlQuery(query) {
          this.isLoading = true
          this.queryControled = true
          
          this.addMessage({
            id: this.nextMessageId,
            role: 'user',
            content: '{"type": "QUERY", "content": "' + query + '"}',
          })
          this.nextMessageId++

          const action = async () => {
            const payload = {
              query: query,
              similarityThreshold: this.currentTeam.preferences.control_module_threshold,
              controlNamespace : this.currentTeam.preferences.control_module_namespace, 
            }
    
            const response = await fetch(generateLambdaUrl('control'), {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            })
            this.shouldAnswer = await response.json()
    
            if(!this.shouldAnswer) {
              this.messages[this.messages.length - 1].content = '{"type": "QUERY", "content": "@#$%%^&*"}'
              await messageApi.createMessage({
                role: 'user',
                content: '{"type": "QUERY", "content": "@#$%%^&*"}',
                conversation_id: this.currentConversationId,
              });
              this.addMessage({
                id: this.nextMessageId,
                role: 'assistant',
                content: '{"type": "ANSWER", "content": "Je suis désolé, je ne suis pas autorisé à répondre à cette demande."}',
              })
              messageApi.createMessage({
                role: 'assistant',
                content: '{"type": "ANSWER", "content": "Je suis désolé, je ne suis pas autorisé à répondre à cette demande."}',
                conversation_id: this.currentConversationId,
              });
              this.nextMessageId++
              this.isLoading = false
              this.userInput = ''
            }
          }
    
          const errorFallback = function() {
            this.isLoading = false
            this.hasError = true
            this.addMessage({
              id: this.nextMessageId,
              role: 'system',
              content: '{"type": "ANSWER", "content": "Une erreur est survenue, veuillez recharger la page. \n Ogma est en cours de développement est n\'est peut être pas totalement opérationnel pour le moment."}',
            })
            this.nextMessageId++
          }
    
          try { await action() } catch (error) {
            errorFallback()
          }
        },
        /*
         * QUERY CONTROL MODULE END ---------------------------------------------------------------------- QUERY CONTROL MODULE END
         */

    handleSources(sources) {
      sources.forEach((source) => {
        source.fileName = source.source.split('/').pop().trim().toLowerCase()
      })
      this.tempSources = sources
    },

    async getTotalMessagesToday() {
      const action = async () => {
        const { data } = await teamApi.getTotalMessagesToday();
        this.totalMessagesToday = data;
      }

      try { action() } catch (error) {
        this.hasError = true
      }
    },
  }
})
