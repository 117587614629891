/**
 * Uploads a document.
 * @param formData
 *
 * @return {Promise}
 */
export default async (formData) =>
  await axios.post('/api/documents/upload', formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
