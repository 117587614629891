import axios from 'axios';

const csrfToken = document.head.querySelector('meta[name="csrf-token"]');

/**
 * The laravel API options requested to be authenticated.
 */
const laravelApiOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    ...(csrfToken ? { 'X-CSRF-TOKEN': csrfToken.content } : {}),
  },
};

/**
 * The laravel API request wrapper.
 * @return {Object}
 */
const laravelApi = {
  get: (url, params = null) =>
    axios.get(
      url,
      params ? { ...laravelApiOptions, params } : laravelApiOptions
    ),

  post: (url, body) => axios.post(url, body, laravelApiOptions),
  put: (url, body) => axios.put(url, body, laravelApiOptions),
  delete: url => axios.delete(url, laravelApiOptions),
};

export { laravelApi };
