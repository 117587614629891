<script setup>
import { TrashSvg } from 'Components/commons/svg'
import ColorConverter from 'Utils/colors'
import { useDashboardStore } from 'Stores/dashboard'
const dashboard = useDashboardStore()

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}
</script>

<template>
  <div 
    @click="dashboard.deleteConversation()"
    class="flex items-center px-2 py-1 dark:text-white text-gray-4 self-end rounded-full border border-px border-red cursor-pointer text-sm hover:bg-cream-2 dark:hover:bg-gray-3"
  >
    <TrashSvg class="mr-2" :color="SvgColor()"/> Supprimer
  </div>
</template>