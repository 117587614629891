<script setup>
import { XSvg, LinkIconSvg } from 'Components/commons/svg'
import { useDashboardStore } from 'Stores/dashboard.js';
import ColorConverter from 'Utils/colors'

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

const dashboard = useDashboardStore()

</script>
<template>
  <div class="fixed top-0 left-0 z-40 bg-gray-3 opacity-70 w-full h-full"></div>
  <div 
    v-click-away="() => $emit('close')"
    class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-5/12 max-h-[78vh] dark:bg-gray-4 bg-cream-1 rounded-lg"
  >
  
    <div id="sourcesmodal" class="overflow-y-scroll w-full p-8 flex flex-col max-h-[78vh]">
      <div class="flex items-center">
        <div class="text-xl text-gray-4 dark:text-white">Source {{ dashboard.displayedSource.index+1 }} du document</div>
        <XSvg :color="SvgColor()" @click="() => $emit('close')" class="ml-auto cursor-pointer"></XSvg>
      </div>
      <!-- <a
        class="text-md text-blue dark:text-blue underline mt-6 flex"
        :href="dashboard.displayedSource.source"
      > -->
      <a
        class="text-md text-blue dark:text-blue underline mt-6 flex cursor-not-allowed"
        title="Disponible prochainement"
      >
        <LinkIconSvg class="mr-2"></LinkIconSvg>
        {{ dashboard.displayedSource.fileName }}
      </a>
      <div class="text-md text-gray-4 dark:text-white mt-4">
        "...{{ dashboard.displayedSource.text }}..."
      </div>
      <div class="text-sm text-gray-4 dark:text-white mt-4">
        Extrait de la page {{ dashboard.displayedSource.pageNumber }}
      </div>
    </div>

    <div class="absolute top-0 left-0 h-6 w-full bg-gradient-to-b from-gray-4 opacity-0 dark:opacity-100 rounded-t-lg"></div>
    <div class="absolute top-0 left-0 h-6 w-full bg-gradient-to-b from-cream-1 dark:opacity-0 opacity-100 rounded-t-lg"></div>

    <div class="absolute -bottom-px left-0 h-6 w-full bg-gradient-to-t from-gray-4 opacity-0 dark:opacity-100 rounded-b-lg"></div>
    <div class="absolute -bottom-px left-0 h-6 w-full bg-gradient-to-t from-cream-1 dark:opacity-0 opacity-100 rounded-b-lg"></div>

  </div>
</template>

<style>
  #sourcesmodal{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #sourcesmodal::-webkit-scrollbar{
    display: none;
  }
</style>