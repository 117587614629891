<script setup>
import UploadDocumentButton from 'Components/documents/UploadDocumentButton.vue';
import DocumentsSearch from 'Components/documents/DocumentsSearch.vue';
import DocumentsSort from 'Components/documents/DocumentsSort.vue';
import DocumentsList from 'Components/documents/DocumentsList.vue';
import {useDocumentsStore} from 'Stores/documents';
import {useConversationStore} from 'Stores/conversation';
import {onMounted, computed} from 'vue';
import SynchronizeDocumentButton from "../documents/SynchronizeDocumentButton.vue";

const documentsStore = useDocumentsStore();
const conversationStore = useConversationStore();

onMounted(() => {
    documentsStore.getDocuments();
    conversationStore.getCurrentTeam();
});

</script>
<template>
    <div class="relative h-full">
        <div class="overflow-y-scroll h-full" id="documentsFullPannel">
            <div class="dark:text-white text-gray-4 p-4 flex flex-col items-start pb-6">
                <div class="flex items-end mt-10 h-9">
                    <div class="mr-5">
                        <UploadDocumentButton></UploadDocumentButton>
                    </div>
                    <div>
                        <SynchronizeDocumentButton/>
                    </div>
                </div>
                <div class="flex w-full justify-between mt-10">
                    <DocumentsSearch></DocumentsSearch>
                    <DocumentsSort></DocumentsSort>
                </div>
                <DocumentsList :documents="documentsStore.documents" class="mt-4"></DocumentsList>
            </div>
        </div>

        <div
            class="absolute z-10 top-0 left-0 h-6 w-full bg-gradient-to-b from-gray-4 opacity-0 dark:opacity-100">
        </div>
        <div
            class="absolute z-10 top-0 left-0 h-6 w-full bg-gradient-to-b from-cream-1 dark:opacity-0 opacity-100">
        </div>
        <div
            class="absolute z-10 bottom-0 left-0 h-6 w-full bg-gradient-to-t from-gray-4 opacity-0 dark:opacity-100">
        </div>
        <div
            class="absolute z-10 bottom-0 left-0 h-6 w-full bg-gradient-to-t from-cream-1 dark:opacity-0 opacity-100">
        </div>
    </div>
</template>

<style>
#documentsFullPannel {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#documentsFullPannel::-webkit-scrollbar {
    display: none;
}
</style>
