<script setup>
import Layout from 'Components/layout/Layout.vue'
import Conversation from 'Components/pannels/Conversation.vue'
import Logo from 'Components/pannels/Logo.vue'
import Profile from 'Components/pannels/Profile.vue'
import Sidebar from 'Components/pannels/Sidebar.vue'
import ConversationsSummary from 'Components/pannels/ConversationsSummary.vue'
import DocumentsSummary from 'Components/pannels/DocumentsSummary.vue'
import ProfileSummary from 'Components/pannels/ProfileSummary.vue'
import DocumentsSidePannel from 'Components/pannels/DocumentsSidePannel.vue'
import DocumentsFullPannel from 'Components/pannels/DocumentsFullPannel.vue'
import DocumentInfoPannel from 'Components/pannels/DocumentInfoPannel.vue'
import ProfilePannel from 'Components/pannels/ProfilePannel.vue'
import Statistics from 'Components/pannels/Statistics.vue'

import { useDashboardStore } from 'Stores/dashboard'
import { useDocumentsStore } from 'Stores/documents'
const dashboard = useDashboardStore()
const documents = useDocumentsStore()

const GrowMidPannel = function() {
  return [
    'documents',
    'statistics',
    'profile',
    'dashboard',
  ].includes(dashboard.currentPageName) && Object.keys(documents.currentDocument).length === 0
}
</script>

<template>
  <Layout :growMidPannel="GrowMidPannel()">

    <template #top-left-pannel>
      <Logo></Logo>
    </template>

    <template #top-mid-pannel>
      <ConversationsSummary
        v-if="dashboard.currentPageName === 'dashboard'"
      ></ConversationsSummary>
      <DocumentsSummary
        v-if="dashboard.currentPageName === 'documents'"
      ></DocumentsSummary>
      <ProfileSummary
        v-if="dashboard.currentPageName === 'profile'"
      ></ProfileSummary>
    </template>

    <template #top-right-pannel>
      <Profile></Profile>
    </template>

    <template #bot-left-pannel>
      <Sidebar></Sidebar>
    </template>

    <template #bot-mid-pannel>
      <Conversation 
        v-if="dashboard.currentPageName === 'dashboard'"
      ></Conversation>
      <DocumentsFullPannel
        v-if="dashboard.currentPageName === 'documents'"
      ></DocumentsFullPannel>
      <ProfilePannel
        v-if="dashboard.currentPageName === 'profile'"
      ></ProfilePannel>
      <Statistics
        v-if="dashboard.currentPageName === 'statistics'"
      ></Statistics>
    </template>

    <template #bot-right-pannel>
      <DocumentInfoPannel
        v-if="dashboard.currentPageName === 'documents' && documents.currentDocument"
      ></DocumentInfoPannel>
    </template>

  </Layout>
</template>