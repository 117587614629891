<script setup>
import { defineProps } from 'vue';
import { debounce } from 'lodash';
import { SearchSvg } from 'Components/commons/svg'
import BadgeSmall from 'Components/commons/badges/BadgeSmall.vue';
import { useDocumentsStore } from "Stores/documents.js";

const documents = useDocumentsStore()

const props = defineProps({
  fromPannel: {
    type: Boolean,
    default: false,
  },
});

const debouncedSearch = debounce(() => {
  documents.setSearchInput(documents.searchInput)
}, 500);

const handleSearch = (e) => {
  documents.searchInput = e.target.value;
  debouncedSearch(e.target.value);
}
</script>

<template>
  <div>
    <label class="relative">
      <SearchSvg class="absolute left-3 top-1"></SearchSvg>

      <input 
        :value="documents.searchInput" 
        @input="handleSearch" 
        type="text"
        :class="[
          'pl-8 border rounded-md text-md dark:border-gray-1 bg-transparent dark:text-gray-1 dark:focus-within:border-white focus:ring-0 dark:focus:text-white',
          fromPannel ? 'w-full' : 'w-60'
        ]" 
        placeholder="Rechercher un fichier..."
      >
    </label>

    <div v-if="fromPannel" class="flex justify-between text-sm mt-3">
      <div class="dark:text-gray-1">Total</div>
      <BadgeSmall :value="documents.documents.length"></BadgeSmall>
    </div>
  </div>
</template>
