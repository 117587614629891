<script setup>
import ToggleViewMode from "Components/documents/ToggleViewMode.vue";
import Document from "Components/documents/Document.vue";
import { defineProps } from 'vue';
import { useDocumentsStore } from "Stores/documents.js";

const documentsStore = useDocumentsStore()

const props = defineProps({
  documents: {
    type: Array,
    default: [],
  },
});

</script>

<template>
  <div class="relative dark:bg-gray-3 bg-cream-2 w-full mt-4 p-6 rounded-lg overflow-hidden">
    <div class="w-full flex justify-between mb-4">
      <div class="z-10 dark:text-white text-gray-4 mb-1 font-roc text-header">
        {{ documents.length }} documents
      </div>
      <ToggleViewMode class="z-10"></ToggleViewMode>
    </div>

    <div 
      v-if="documentsStore.viewMode == 'grid'" 
      class="flex flex-wrap -mx-3"
    >
      <Document 
        v-for="document in documents"
        :document="document"
        :key="document.id"
      ></Document>
    </div>

    <div 
      v-else
      class="flex flex-col items-start justify-start"
    >
      <div class="z-10 h-8 w-full flex items-center py-3 px-3 dark:bg-gray-2 bg-cream-4 rounded-t-lg border border-gray-1">
        <div class="mr-6 w-2/4 truncate text-gray-4 dark:text-white">Nom du document</div>
			  <div class="mr-6 w-1/4 truncate text-gray-4 dark:text-white">Date de publication</div>	
			  <div class="mr-6 w-1/4 truncate text-gray-4 dark:text-white">Date de dernière édition</div>
      </div>
      <Document 
        v-for="document in documents"
        :document="document"
        :key="document.id"
      ></Document>
    </div>

    <div class="z-0 absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/2 rounded-full h-96 w-96 bg-gradient-radial from-[#F511FA2D] via-[#F511FA20] dark:from-[#4E74F93D] dark:via-[#4E74F930] to-transparent via-25% to-70%"></div>
    <div class="z-0 absolute top-6 right-0 transform translate-x-1/2 rounded-full h-96 w-96 bg-gradient-radial from-[#64F0AD3D] via-[#64F0AD30] to-transparent via-25% to-70%"></div>
  </div>
</template>
