import { defineStore } from 'pinia'
import * as conversationApi from 'Api/conversations'
import { useConversationStore } from 'Stores/conversation'
import { useDocumentsStore } from 'Stores/documents'
import { Routes } from 'Utils/routes'

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    isLoading: false,
    hasError: false,
    currentConversation: null,
    conversations: [],
    darkModeEnabled: sessionStorage.getItem('theme') === 'dark',
    currentPageName: null,
    displaySourcesModal: false,
    displayedSource: {},
  }),

  actions: {
    setCurrentPage(page) {
      if (page.name === this.currentPageName) return
      const documentsStore = useDocumentsStore()
      documentsStore.currentDocument = {};
      this.currentPageName = page.name
      window.history.pushState({}, '', page.path)
    },

    initializeCurrentPage() {
      let page = window.location.pathname.split('/').slice(-1)[0]
      if (page == '') page = window.location.pathname.split('/').slice(-2)[0]
      const route = Routes.find(route => route.name === page)
      if (route) this.setCurrentPage(route)
    },

    async getConversations() {
      const action = async () => {
        const { data } = await conversationApi.listConversations();
        this.conversations = data;
        this.currentConversation = this.conversations[0];
        
        this.setCurrentConversation(this.currentConversation);

        this.isLoading = false;
      }; 

      const errorFallback = function() {
        this.isLoading = false;
        this.hasError = true
      }
  
      this.isLoading = true
  
      try { action() } catch (error) { 
        errorFallback()
      }
    },

    setCurrentConversation(conversation) {
      const conversationStore = useConversationStore();

      this.currentConversation = conversation
      this.displaySourcesModal = false
      this.displayedSource = {}

      conversationStore.tempSources = []
      conversationStore.currentConversationId = conversation.id
      conversationStore.messages = conversation.messages
      conversationStore.nextMessageId = conversation.messages.slice(-1)[0].id + 1
      conversationStore.userInput = ''
    },

    async createConversation() {
      const action = async () => {
        await conversationApi.createConversation({name:'Nouvelle conversation'});
        this.getConversations();
      }

      const errorFallback = function() {
        this.hasError = true
        this.addMessage({
          id: this.nextMessageId,
          role: 'system',
          content: '{"type": "ANSWER", "content": "Une erreur est survenue, veuillez recharger la page. \n Ogma est en cours de développement est n\'est peut être pas totalement opérationnel pour le moment."}',
        })
        this.nextMessageId++
      }
  
      this.isLoading = true
  
      try { action() } catch (error) { 
        errorFallback()
      }
    },

    async deleteConversation() {
      const action = async () => {
        await conversationApi.deleteConversation({conversationId: this.currentConversation.id});
        this.getConversations();
      }

      const errorFallback = function() {
        this.hasError = true
        this.addMessage({
          id: this.nextMessageId,
          role: 'system',
          content: '{"type": "ANSWER", "content": "Une erreur est survenue, veuillez recharger la page. \n Ogma est en cours de développement est n\'est peut être pas totalement opérationnel pour le moment."}',
        })
        this.nextMessageId++
      }
  
      this.isLoading = true
  
      try { action() } catch (error) { 
        errorFallback()
      }
    },

    async resetConversation() {
      const action = async () => {
        const conversation = this.currentConversation;
        if (conversation.is_welcome) {
          await conversationApi.resetWelcomeConversation({conversationId: conversation.id});
        } else {
          await conversationApi.deleteConversation({conversationId: conversation.id});
          await conversationApi.createConversation({name: conversation.name});
        }
        this.getConversations();
      }

      const errorFallback = function() {
        this.hasError = true
        this.addMessage({
          id: this.nextMessageId,
          role: 'system',
          content: '{"type": "ANSWER", "content": "Une erreur est survenue, veuillez recharger la page. \n Ogma est en cours de développement est n\'est peut être pas totalement opérationnel pour le moment."}',
        })
        this.nextMessageId++
      }

      this.isLoading = true

      try { action() } catch (error) { 
        errorFallback()
      }
    },
  },
})