<script setup>
import { computed, ref } from 'vue';
import { useUserStore } from 'Stores/user';
import UpdateButton from 'Components/commons/buttons/UpdateButton.vue';
import PasswordInput from 'Components/commons/inputs/PasswordInput.vue';

const userStore = useUserStore();
const passwordValidationRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');

const currentPasswordError = ref(false);
const newPasswordError = ref(false);
const confirmPasswordError = ref(false);
const submitIsSuccessful = ref(false);

const passwordIsValid = computed(() => passwordValidationRules.test(newPassword.value));
const confirmPasswordIsValid = computed(() => newPassword.value === confirmPassword.value);
const hasError = computed(() => userStore.hasError);
const errorMessage = computed(() => userStore.errors.message);

const formIsValid = computed(() => 
  passwordIsValid.value && 
  confirmPasswordIsValid.value && 
  currentPassword.value !== '' &&
  !currentPasswordError.value &&
  !newPasswordError.value &&
  !confirmPasswordError.value
);

const handleInputUpdate = ({ inputName, inputValue, hasError }) => {
  switch (inputName) {
    case 'currentPassword':
      currentPassword.value = inputValue;
      currentPasswordError.value = hasError;
      break;
    case 'newPassword':
      newPassword.value = inputValue;
      newPasswordError.value = hasError;
      break;
    case 'confirmPassword':
      confirmPassword.value = inputValue;
      confirmPasswordError.value = hasError;
      break;
  }
}

const submitForm = async () => {
  await userStore.updateUserPassword(currentPassword.value, newPassword.value);
  submitIsSuccessful.value = !hasError.value;
};
</script>

<template>
  <div class="flex flex-col h-full w-full">
    <div class="text-xl font-roc mb-6 text-gray-4 dark:text-white">Mettre à jour mon mot de passe</div>
    <p class="text-md font-bold text-gray-4 dark:text-white mb-2">Votre mot de passe doit contenir au minimum :</p>
    <ul class="text-sm list-disc pl-3 mb-8">
      <li>Une majuscule</li>
      <li>Un chiffre</li>
      <li>Un caractère spécial</li>
      <li>8 caractères</li>
    </ul>

    <form @submit.prevent="submitForm" class="flex flex-col w-full">
      <PasswordInput
        label="Mot de passe actuel"
        inputId="currentPassword"
        inputName="currentPassword"
        :inputValue="currentPassword"
        autoComplete="current-password"
        @update:inputState="handleInputUpdate"
        class="mb-6"
      ></PasswordInput>

      <PasswordInput
        label="Nouveau mot de passe"
        inputId="newPassword"
        inputName="newPassword"
        :inputValue="newPassword"
        autoComplete="new-password"
        @update:inputState="handleInputUpdate"
        class="mb-6"
      ></PasswordInput>

      <PasswordInput
        label="Confirmer le nouveau mot de passe"
        inputId="confirmPassword"
        inputName="confirmPassword"
        :inputValue="confirmPassword"
        :newPasswordValue="newPassword"
        @update:inputState="handleInputUpdate"
        class="mb-6"
      ></PasswordInput>

      <div
        v-if="!hasError && submitIsSuccessful" 
        class="flex text-blue text-sm mb-6"
      >
        <svg class="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0791 3.08748C12.307 3.31529 12.307 3.68463 12.0791 3.91244L5.66248 10.3291C5.43467 10.5569 5.06533 10.5569 4.83752 10.3291L1.92085 7.41244C1.69305 7.18463 1.69305 6.81529 1.92085 6.58748C2.14866 6.35967 2.51801 6.35967 2.74581 6.58748L5.25 9.09167L11.2542 3.08748C11.482 2.85967 11.8513 2.85967 12.0791 3.08748Z" fill="#4E74F9"/>
        </svg> 
        <p>Votre mot de passe a bien été mis à jour !</p>
      </div>

      <div
        v-if="hasError" 
        class="flex text-red text-sm mb-6"
      >
        <svg class="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.92085 1.92085C1.69305 1.69305 1.69305 1.32372 1.92085 1.09591C2.14866 0.868097 2.51801 0.868097 2.74581 1.09591L7 5.35009L11.2542 1.09591C11.482 0.868097 11.8513 0.868097 12.0791 1.09591C12.307 1.32372 12.307 1.69305 12.0791 1.92085L7.82485 6.17509L12.0791 10.4293C12.307 10.6571 12.307 11.0264 12.0791 11.2542C11.8513 11.482 11.482 11.482 11.2542 11.2542L7 7L2.74581 11.2542C2.51801 11.482 2.14866 11.482 1.92085 11.2542C1.69305 11.0264 1.69305 10.6571 1.92085 10.4293L6.17509 6.17509L1.92085 1.92085Z" fill="#FF5C5C"/>
        </svg> 
        {{ errorMessage }}
      </div>
      <UpdateButton :disabled="!formIsValid" type="submit" @click="submitForm"></UpdateButton>
    </form>
  </div>
</template>
