<script setup>
import { OgmaLogoSvg } from 'Components/commons/svg'
import { useDashboardStore } from 'Stores/dashboard'
import ColorConverter from 'Utils/colors'
const dashboard = useDashboardStore()
function LogoColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}
</script>

<template>
  <div class="flex h-full items-center justify-center">
    <OgmaLogoSvg :color="LogoColor()"></OgmaLogoSvg>
  </div>
</template>