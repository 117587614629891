<script setup>
import { useDashboardStore } from 'Stores/dashboard'
const dashboard = useDashboardStore()
</script>

<template>
  <div class="loader block relative w-[22px] h-[22px]">
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
    <div class="block top-[8px] left-[10px] absolute bg-gray-4 dark:bg-white h-[6px] w-[2px] rounded-full"></div>
  </div>
</template>

<style scoped>
.loader div {
  transform-origin: center;
  animation: loader 0.8s linear infinite;
}
.loader div:nth-child(1) {
  transform: rotate(0deg) translateY(8px);
  animation-delay: -0.7s;
}
.loader div:nth-child(2) {
  transform: rotate(45deg) translateY(8px);
  animation-delay: -0.6s;
}
.loader div:nth-child(3) {
  transform: rotate(90deg) translateY(8px);
  animation-delay: -0.5s;
}
.loader div:nth-child(4) {
  transform: rotate(135deg) translateY(8px);
  animation-delay: -0.4s;
}
.loader div:nth-child(5) {
  transform: rotate(180deg) translateY(8px);
  animation-delay: -0.3s;
}
.loader div:nth-child(6) {
  transform: rotate(225deg) translateY(8px);
  animation-delay: -0.2s;
}
.loader div:nth-child(7) {
  transform: rotate(270deg) translateY(8px);
  animation-delay: -0.1s;
}
.loader div:nth-child(8) {
  transform: rotate(315deg) translateY(8px);
  animation-delay: -0s;
}

@keyframes loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>