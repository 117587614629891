<script setup>
import { XSvg } from 'Components/commons/svg'
import { useDocumentsStore } from 'Stores/documents.js';
import { useDashboardStore } from 'Stores/dashboard.js';
import { useConversationStore } from "Stores/conversation.js";
import ColorConverter from 'Utils/colors'

const documents = useDocumentsStore()
const dashboard = useDashboardStore()
const conversation = useConversationStore()

function SvgColor() {
  return dashboard.darkModeEnabled ? 'white' : ColorConverter('gray4')
}

function handleDeletion(id) {
    documents.deleteDocument(id)
    conversation.getCurrentTeam();
}

</script>
<template>
  <div class="fixed top-0 left-0 z-40 bg-gray-3 opacity-70 w-full h-full"></div>
  <div
    v-click-away="() => $emit('close')"
    class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96 h-48 dark:bg-gray-4 bg-cream-1 rounded-lg flex flex-col p-8"
  >
    <div class="flex items-center">
      <div class="text-xl text-gray-4 dark:text-white">Supprimer le document</div>
      <XSvg :color="SvgColor()" @click="() => $emit('close')" class="ml-auto cursor-pointer"></XSvg>
    </div>
    <div class="text-md text-gray-4 dark:text-white mt-6">Souhaitez-vous vraiment supprimer ce document ?</div>
    <div class="flex items-center mt-6 w-full justify-between">
      <div
        @click="handleDeletion(documents.currentDocument.id); documents.getDocuments(); $emit('close')"
        class="bg-red w-56 h-8 flex justify-center items-center text-md text-white rounded-lg cursor-pointer"
      >
        Supprimer
      </div>
      <div
        @click="() => $emit('close')"
        class="dark:bg-gray-3 bg-cream-3 dark:hover:bg-gray-2 w-20 h-8 flex justify-center items-center text-md text-gray-4 dark:text-white rounded-lg cursor-pointer"
      >
        Annuler
      </div>
    </div>
  </div>
</template>
